import { useState, useEffect } from 'react'

import styles from './ManageTrustedUsersModal.module.scss'
import { StylesProvider } from '@material-ui/core/styles'
import { withLocalize } from 'react-localize-redux'
import {
  InputAdornment,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button
} from '@material-ui/core'
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'
import { useForm } from 'react-hook-form'
import cx from 'classnames'
import PropTypes from 'prop-types'

const ManageTrustedUsersModal = props => {
  const { translate, projects, user, handleConfrimDeleteUsers } = props

  const [attachedProjects, setAttachedProjects] = useState({})
  const [trustedUser, setTrustedUser] = useState([])
  const [selectedEmails, setSelectedEmails] = useState([])
  const [filteredTrustedUser, setFilteredTrustedUser] = useState([])
  const { register, errors, setError, clearErrors } = useForm()

  useEffect(() => {
    const emailsConfAux = []
    const emailsUnconfAux = []
    for (let i = 0; i < user.toJS().trustedUser?.length; i++) {
      emailsConfAux[i] = {
        email: user.toJS().trustedUser[i],
        confirmed: true
      }
    }
    for (let i = 0; i < user.toJS().unTrustedUser?.length; i++) {
      emailsUnconfAux[i] = {
        email: user.toJS().unTrustedUser[i],
        confirmed: false
      }
    }

    setTrustedUser([...emailsConfAux, ...emailsUnconfAux])
    setFilteredTrustedUser([...emailsConfAux, ...emailsUnconfAux])
  }, [user])
  useEffect(() => {
    const emailCount = {}
    trustedUser.forEach(user => {
      emailCount[user.email] = []
    })
    projects.forEach(project => {
      const projectCustomers = project.Meta.ProjectCustomers
      Object.values(projectCustomers).forEach(customer => {
        const email = customer.email
        if (emailCount.hasOwnProperty(email)) {
          emailCount[email] = [...emailCount[email], project.ProjectId]
        }
      })
    })

    setAttachedProjects(emailCount)
  }, [trustedUser, projects])
  const handleSelectEmail = e => {
    if (e.target.checked) {
      setSelectedEmails([...selectedEmails, e.target.value])
    } else {
      setSelectedEmails(selectedEmails.filter(email => email !== e.target.value))
    }
  }
  const handleSelectAllEmails = e => {
    if (e.target.checked) {
      const emails = trustedUser.map(user => user.email)
      setSelectedEmails(emails)
    } else {
      setSelectedEmails([])
    }
  }
  const onClickCancel = () => {
    props.hideModal()
  }

  const handleFilterExpressionChange = event => {
    const value = event.target.value

    if (value === '') {
      setFilteredTrustedUser(trustedUser)
    } else {
      const filtered = trustedUser.filter(user =>
        user.email.toLowerCase().includes(value.toLowerCase())
      )
      setFilteredTrustedUser(filtered)
    }
  }

  const handleDelete = emails => {
    if (emails.length > 0) {
      clearErrors('emails')
      const selectedEmailProjectIds = emails.reduce((acc, email) => {
        acc[email] = attachedProjects[email]
        return acc
      }, {})
      handleConfrimDeleteUsers(selectedEmailProjectIds)
    } else {
      setError('emails', 'required')
    }
  }
  return (
    <StylesProvider injectFirst>
      <div className={styles.modalContainer}>
        <Typography className={styles.header}>{translate('manageUsers')}</Typography>
        <div className={styles.modal}>
          <form noValidate>
            <Typography className={styles.subTitle}>{translate('selectUsers')}</Typography>
            <Typography className={styles.text}>{translate('checkUser')}</Typography>
            <div className={styles.searchAddWrapper}>
              <TextField
                className={styles.searchBar}
                placeholder={translate('alertsPage.searchAlerts')}
                onChange={handleFilterExpressionChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchOutlinedIcon />
                    </InputAdornment>
                  )
                }}
                variant='outlined'
              />
            </div>
            <div className={styles.checkboxEmailWrapper}>
              <FormControlLabel
                className={styles.selectAllCheckbox}
                control={
                  <Checkbox
                    className={cx(styles.checkbox)}
                    classes={{ checked: styles.checkedBox }}
                    name='selectAllEmails'
                    id='selectAllEmails'
                    onChange={handleSelectAllEmails}
                  />
                }
                label={translate('selectAll')}
              />
              {filteredTrustedUser.map((email, i) => {
                return (
                  <div
                    key={i + 'emailData'}
                    className={styles.checkboxFormDiv}
                  >
                    <FormControlLabel
                      className={
                        (styles.checkboxForm,
                        `${email.confirmed ? `${styles.confirmed}` : `${styles.notConfirmed}`} `)
                      }
                      control={
                        <Checkbox
                          className={cx(styles.checkbox)}
                          id={email}
                          classes={{ checked: styles.checkedBox }}
                          required
                          onChange={handleSelectEmail}
                          checked={selectedEmails.includes(email.email)}
                          name='emailRecipients'
                          value={email.email}
                          inputRef={register()}
                          disableRipple
                        />
                      }
                      label={email.email}
                    />
                    <Typography className={styles.emailCount}>
                      {`(${attachedProjects[email.email]?.length} ${
                        attachedProjects[email.email]?.length > 1
                          ? translate('projects')
                          : translate('project')
                      })`}{' '}
                    </Typography>
                  </div>
                )
              })}
            </div>
            {errors.emails && (
              <div className={styles.error}>
                <small>{translate('validation.anyEmailRequired')}</small>
              </div>
            )}
            <div className={styles.buttonWrapper}>
              <Button
                fullWidth
                variant='contained'
                className={styles.deleteButton}
                onClick={() => handleDelete(selectedEmails)}
              >
                {translate('deleteUsers')}
              </Button>
            </div>
            <div className={styles.buttonWrapper}>
              <Button
                fullWidth
                disableRipple
                type='submit'
                variant='contained'
                className={styles.buttonCancelar}
                onClick={onClickCancel}
              >
                {translate('addReportModal.cancel')}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </StylesProvider>
  )
}
ManageTrustedUsersModal.propTypes = {
  translate: PropTypes.func.isRequired
}

export default withLocalize(ManageTrustedUsersModal)
