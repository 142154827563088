import { useState } from 'react'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { Modal } from '@material-ui/core'
import styles from './ManageTrustedUsersModalContainer.module.scss'
import ManageTrustedUsersModal from '../components/ManageTrustedUsersModal'
import { getProjectsRaw } from 'common/selectors/projects'
import { getUser } from 'common/selectors/user'
import ConfirmationModal from 'modules/modals/ConfirmationModal'
import { deleteTrustedUser } from 'common/actions/user'

const ManageTrustedUsersModalContainer = props => {
  const [open] = useState(true)
  const { hideModal, projects, user, translate, deleteTrustedUser } = props
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const [dataState, setDataState] = useState({})
  const handleConfrimDeleteUsers = data => {
    const jsonData = {}
    jsonData.userId = user.toJS()._id
    jsonData.sharedProjects = data
    setDataState(jsonData)
    setOpenConfirmationModal(true)
  }
  const onConfirmDeleteUser = data => {
    deleteTrustedUser(data)
    setOpenConfirmationModal(false)
  }
  const projectJS = []
  if (projects) {
    projects.toArray().map(a => projectJS.push(a.toJS()))
  }
  return (
    <div>
      <Modal
        open={open}
        onClose={hideModal}
        scroll='body'
        BackdropProps={{
          classes: {
            root: styles.backdrop
          }
        }}
        arial-labelledby='simple-modal-title'
        arial-desccribedby='simple-modal-description'
      >
        <div>
          <ManageTrustedUsersModal
            {...props}
            projects={projectJS}
            user={user}
            handleConfrimDeleteUsers={handleConfrimDeleteUsers}
          />
        </div>
      </Modal>
      {openConfirmationModal && (
        <ConfirmationModal
          hideModal={() => setOpenConfirmationModal(false)}
          translate={translate}
          contentText={translate('warningUsers')}
          onConfirm={() => onConfirmDeleteUser(dataState)}
        />
      )}
    </div>
  )
}

ManageTrustedUsersModalContainer.propTypes = {
  hideModal: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  projects: getProjectsRaw(state),
  user: getUser(state)
})

const mapActionsToProps = {
  deleteTrustedUser
}

export default withLocalize(
  connect(mapStateToProps, mapActionsToProps)(ManageTrustedUsersModalContainer)
)
