import { createReducer } from 'utils'
import { fromJS } from 'immutable'
import { constants } from 'common/actions/projects'

export const initialState = fromJS({
  isSubmitting: false,
  error: null
})

export default createReducer(initialState, {
  [constants.SHARE_DEVICES_REQUEST.START]: state => state.set('isSubmitting', true),
  [constants.SHARE_DEVICES_REQUEST.SUCCESS]: () => initialState,
  [constants.SHARE_DEVICES_REQUEST.FAILURE]: (state, { payload: error }) => {
    return state.set('isSubmitting', false).set('error', error)
  }
})
