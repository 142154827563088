const PROJECT_STORE_KEY = 'selectedProjectId'

export const setProjectStoreData = projectId => {
  localStorage.setItem(PROJECT_STORE_KEY, projectId)
}

export const getProjectStoreData = () => {
  return localStorage.getItem(PROJECT_STORE_KEY)
}

export const clearProjectStoreData = () => {
  localStorage.removeItem(PROJECT_STORE_KEY)
}
