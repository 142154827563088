import { useState } from 'react'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { Modal } from '@material-ui/core'
import styles from './ShareProjectModalContainer.module.scss'
import { hideModal } from 'common/actions/modal'
import { getUser } from 'common/selectors/user'
import { getError, getIsSubmitting, getIsAddingEmail } from '../selectors'
import ShareProjectModal from '../components/ShareProjectModal'
import { addTrustedUser } from 'common/actions/user'
import { shareProject } from 'common/actions/projects'
const ShareProjectModalContainer = props => {
  const [open] = useState(true)
  const { hideModal, submitError, isSubmitting, project, isAddingEmail, user, shareProject } = props
  const userjs = user.toJS()
  const handleSubmit = async data => {
    const jsonData = {}
    jsonData.attachedUsers = data.attachedUsers
    jsonData.user_id = userjs._id
    jsonData.mainEmail = userjs.email
    jsonData.projectId = project.ProjectId
    jsonData.language = userjs.language
    shareProject(jsonData)
  }
  return (
    <div>
      <Modal
        open={open}
        onClose={hideModal}
        scroll='body'
        BackdropProps={{
          classes: {
            root: styles.backdrop
          }
        }}
        arial-labelledby='simple-modal-title'
        arial-desccribedby='simple-modal-description'
      >
        <div>
          <ShareProjectModal
            hideModal={hideModal}
            submitError={submitError}
            isSubmitting={isSubmitting}
            isAddingEmail={isAddingEmail}
            project={project}
            onSubmit={handleSubmit}
          />
        </div>
      </Modal>
    </div>
  )
}

ShareProjectModalContainer.propTypes = {
  hideModal: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  user: getUser(state),
  submitError: getError(state),
  isSubmitting: getIsSubmitting(state),
  isAddingEmail: getIsAddingEmail(state)
})

const mapActionsToProps = {
  hideModal,
  addTrustedUser,
  shareProject
}

export default withLocalize(connect(mapStateToProps, mapActionsToProps)(ShareProjectModalContainer))
