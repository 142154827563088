import { useState } from 'react'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { Modal } from '@material-ui/core'
import styles from './ManageDevicesModalContainer.module.scss'
import ManageDevicesModal from '../components/ManageDevicesModal'
import { getUser } from 'common/selectors/user'
import { deleteTrustedUser } from 'common/actions/user'
import { shareDevices } from 'common/actions/projects'
import { getIsSubmitting } from '../selectors'

const ManageDevicesModalContainer = props => {
  const [open] = useState(true)
  const { hideModal, user, systems, userSystems, shareDevices, isSubmitting, projectId } = props

  const handleSubmit = data => {
    const jsonData = {}
    jsonData['project_id'] = projectId
    jsonData['devices'] = data.devices
    jsonData['user_id'] = user.toJS()._id
    shareDevices(jsonData)
  }
  const userSystemsJS = []
  if (userSystems) {
    userSystems.toArray().map(a => userSystemsJS.push(a.toJS()))
  }
  const systemJS = []
  if (systems) {
    systems.toArray().map(a => systemJS.push(a.toJS()))
  }
  return (
    <div>
      <Modal
        open={open}
        onClose={hideModal}
        scroll='body'
        BackdropProps={{
          classes: {
            root: styles.backdrop
          }
        }}
        arial-labelledby='simple-modal-title'
        arial-desccribedby='simple-modal-description'
      >
        <div>
          <ManageDevicesModal
            {...props}
            user={user}
            systems={systemJS}
            userSystems={userSystemsJS}
            onSubmit={handleSubmit}
            isSubmitting={isSubmitting}
          />
        </div>
      </Modal>
    </div>
  )
}

ManageDevicesModalContainer.propTypes = {
  hideModal: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  user: getUser(state),
  isSubmitting: getIsSubmitting(state)
})

const mapActionsToProps = {
  deleteTrustedUser,
  shareDevices
}

export default withLocalize(
  connect(mapStateToProps, mapActionsToProps)(ManageDevicesModalContainer)
)
