import { useState } from 'react'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { Modal } from '@material-ui/core'
import styles from './AddProjectModalContainer.module.scss'
import { hideModal } from 'common/actions/modal'
import AddProjectModal from '../components/AddProjectModal'
import { createProject } from 'common/actions/projects'
import { getUser } from 'common/selectors/user'
import { getError, getIsSubmitting } from '../selectors'

const AddProjectModalContainer = props => {
  const [open] = useState(true)
  const { hideModal, user, createProject, submitError, isSubmitting } = props
  const handleSubmit = async data => {
    const jsonData = {}

    jsonData.projectName = data.projectName
    jsonData.customerId = user.toJS()._id
    jsonData.projectDescription = data.projectDescription
    createProject(jsonData)
  }
  return (
    <div>
      <Modal
        open={open}
        onClose={hideModal}
        scroll='body'
        BackdropProps={{
          classes: {
            root: styles.backdrop
          }
        }}
        arial-labelledby='simple-modal-title'
        arial-desccribedby='simple-modal-description'
      >
        <div>
          <AddProjectModal
            hideModal={hideModal}
            onSubmit={handleSubmit}
            submitError={submitError}
            isSubmitting={isSubmitting}
          />
        </div>
      </Modal>
    </div>
  )
}

AddProjectModalContainer.propTypes = {
  hideModal: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  user: getUser(state),
  submitError: getError(state),
  isSubmitting: getIsSubmitting(state)
})

const mapActionsToProps = {
  hideModal,
  createProject
}

export default withLocalize(connect(mapStateToProps, mapActionsToProps)(AddProjectModalContainer))
