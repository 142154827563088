import { createSelector } from 'reselect'
import { denormalize } from 'normalizr'
import * as schemas from 'schemas'
import { fromJS } from 'immutable'

import {
  getEntities,
  getSystems as getSystemsEntities,
  getWeather as getWeatherEntities,
  getUserSystems as getUserSystemsEntities
} from './entities'

export const getSystemsRaw = createSelector(
  [getEntities, getSystemsEntities, getWeatherEntities],
  (entities, systems, weather) => {
    if (!systems) {
      return fromJS({})
    }
    systems = systems.map(s => {
      const systemWeather = weather.get(s.get('baseStationLocation'))
      if (systemWeather) {
        s.set('weather', systemWeather)
      }
      return s
    })
    return denormalize(systems, [schemas.system], entities)
  }
)

export const getUserSystemsRaw = createSelector(
  [getEntities, getUserSystemsEntities],
  (entities, userSystems) => {
    return denormalize(userSystems, [schemas.userSystems], entities)
  }
)

export const getSystemByIdRaw = id =>
  createSelector([getEntities], entities => {
    const systems = entities.get('systems')
    if (!systems) {
      return null
    }
    const weather = entities.get('weather')
    const system = systems.find(s => s.get('_id') === id)
    if (!system) return null
    const systemWeather = weather.get(system.get('baseStationLocation'))
    if (systemWeather) {
      system.set('weather', systemWeather)
    }
    return denormalize(system, schemas.system, entities)
  })

export const getSystemById = id =>
  createSelector([getSystemsEntities], entities => entities.find(s => s.get('_id') === id))
