export const getEntities = state => state.entities
export const getUsers = state => state.entities.get('users')
export const getSystems = state => state.entities.get('systems')
export const getScreens = state => state.entities.get('screens')
export const getAlarms = state => state.entities.get('alarms')
export const getInactivityAlarms = state => state.entities.get('inactivityAlarms')
export const getData = state => state.entities.get('data')
export const getWeather = state => state.entities.get('weather')
export const getUserProjects = state => state.entities.get('projects')
export const getSelectedProject = state => state.entities.get('selectedProject')
export const getUserSystems = state => state.entities.get('userSystems')
