import * as Routes from 'constants/Routes'
import { injectReducer } from 'common/reducers'

export default store => ({
  path: `${Routes.CHECK_TRUSTED_USER_STATUS}/:email/:mainEmail/:projectId`,
  getComponent: async function (nextState, cb) {
    const [module, reducer] = await Promise.all([
      import('./containers/CheckTrustedUserStatusContainer'),
      import('./reducers')
    ])

    injectReducer(store, { key: 'checkTrustedUserStatus', reducer: reducer.default })

    cb(null, module.default)
  }
})
