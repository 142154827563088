import { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import { Map } from 'immutable'

import { withStyles } from '@material-ui/core/styles'
import { Modal, Paper } from '@material-ui/core'

import styles from './AddFullScreenViewContainer.module.scss'
import AddFullScreenViewForm from '../components/AddFullScreenViewForm/AddFullScreenViewForm'
import { showSnackbar } from 'common/actions/snackbar'
import { createScreen } from 'common/actions/systems'
import { getError, getIsSubmitting } from '../selectors'
import { getUser } from 'common/selectors/user'

const AddFullScreenViewContainer = props => {
  const [open] = useState(true)

  const { hideModal, user, createScreen, submitError, isSubmitting, projectId } = props
  const handleSubmit = data => {
    const jsonData = {}
    const Meta = {}
    for (let i = 0; i < Object.keys(data).length; i++) {
      if (
        Object.keys(data)[i] !== 'screenName' &&
        Object.keys(data)[i] !== 'checkedSwitch' &&
        Object.keys(data)[i] !== 'showLogo' &&
        Object.keys(data)[i] !== 'selectedDevice' &&
        Object.keys(data)[i] !== 'indicator'
      ) {
        Meta[Object.keys(data)[i]] = data[Object.keys(data)[i]]
      }
      if (Object.keys(data)[i] === 'indicator' && data[Object.keys(data)[i]] !== 'none') {
        Meta[data[Object.keys(data)[i]]] = true
      }
    }
    jsonData.Meta = Meta
    jsonData.name = data.screenName
    jsonData.DeviceId = data.selectedDevice
    jsonData.CustomerId = user.toJS()._id
    jsonData.projectId = projectId
    jsonData.publicAccess = data.checkedSwitch
    jsonData.showLogo = data.showLogo

    createScreen(jsonData)
    hideModal()
  }

  const StyledPaper = withStyles({
    root: {
      maxHeight: '100% !important',
      'overflow-y': 'visible',
      fontFamily: 'Roboto',
      verticalAlign: 'top',
      maxWidth: '500px',
      backgroundColor: '#fffff'
    }
  })(props => <Paper {...props} />)

  return (
    <div>
      <Modal
        open={open}
        onClose={hideModal}
        scroll='body'
        BackdropProps={{
          classes: {
            root: styles.backdrop
          }
        }}
        PaperComponent={StyledPaper}
        arial-labelledby='simple-modal-title'
        arial-desccribedby='simple-modal-description'
      >
        <div className={styles.content}>
          <AddFullScreenViewForm
            {...props}
            // systems={systems}
            // systemsByGroup={systemsByGroup}
            hideModal={hideModal}
            onSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            submitError={submitError}
          />
        </div>
      </Modal>
    </div>
  )
}

AddFullScreenViewContainer.propTypes = {
  submitError: PropTypes.any,
  hideModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Map)
}

const mapStateToProps = state => ({
  user: getUser(state),

  submitError: getError(state),
  isSubmitting: getIsSubmitting(state)
})

const mapActionsToProps = {
  showSnackbar,
  createScreen
}

export default withLocalize(connect(mapStateToProps, mapActionsToProps)(AddFullScreenViewContainer))
