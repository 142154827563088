import { useState } from 'react'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { Modal } from '@material-ui/core'
import styles from './EditProjectModalContainer.module.scss'
import { hideModal } from 'common/actions/modal'
import EditProjectModal from '../components/EditProjectModal'
import { updateProject } from 'common/actions/projects'
import { getError, getIsSubmitting } from '../selectors'

const EditProjectModalContainer = props => {
  const [open] = useState(true)
  const { hideModal, submitError, isSubmitting, project, updateProject } = props
  const handleSubmit = async data => {
    const jsonData = {}
    jsonData.name = data.projectName
    jsonData.projectDescription = data.projectDescription
    const actionData = {
      ProjectId: project.ProjectId,
      jsonData
    }

    updateProject(actionData)
  }
  return (
    <div>
      <Modal
        open={open}
        onClose={hideModal}
        scroll='body'
        BackdropProps={{
          classes: {
            root: styles.backdrop
          }
        }}
        arial-labelledby='simple-modal-title'
        arial-desccribedby='simple-modal-description'
      >
        <div>
          <EditProjectModal
            hideModal={hideModal}
            submitError={submitError}
            isSubmitting={isSubmitting}
            onSubmit={handleSubmit}
            project={project}
          />
        </div>
      </Modal>
    </div>
  )
}

EditProjectModalContainer.propTypes = {
  hideModal: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  submitError: getError(state),
  isSubmitting: getIsSubmitting(state)
})

const mapActionsToProps = {
  hideModal,
  updateProject
}

export default withLocalize(connect(mapStateToProps, mapActionsToProps)(EditProjectModalContainer))
