import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withLocalize } from 'react-localize-redux'
import cx from 'classnames'
import { useForm } from 'react-hook-form'
import { StylesProvider } from '@material-ui/core/styles'
import {
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  FormControl,
  Tooltip,
  MenuItem,
  Select
} from '@material-ui/core'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import localeSpanish from 'date-fns/locale/es'
import localeEnglish from 'date-fns/locale/en-US'
import styles from './DownloadSettingsForm.module.scss'
import moment from 'moment'
import { ArrowBack, ArrowForward } from '@material-ui/icons'
import { LoadingButton } from '@mui/lab'
import Languages from 'constants/Languages'
import EventIcon from '@mui/icons-material/Event'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { dataGroupingDefault, dataGroupingValues } from 'constants/DataGrouping'

const DownloadSettingsForm = props => {
  const subtractDays = (n, date) => {
    date.setTime(date.getTime() - n * 24 * 3600 * 1000)
    return date
  }

  const {
    onSubmit,
    translate,
    activeLanguage,
    system,
    sortedData,
    isPremium,
    submitError,
    setSubmitError,
    isManager,
    isOwner
  } = props
  const { handleSubmit, register, errors, getValues, setError, clearErrors, formState } = useForm()
  const [startDate, setStartDate] = useState(subtractDays(1, new Date()))
  const [endDate, setEndDate] = useState(new Date())

  const { isSubmitting } = formState

  const [type, setType] = useState([])
  const [value, setValue] = useState('csv')
  const [orderValue, setOrderValue] = useState('0')
  const [groupingValue, setGroupingValue] = useState('c')
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const [dataState, setDataState] = useState({})
  const [typeChecked, setTypeChecked] = useState([])
  const [checkAllIsClicked, setCheckAllIsClicked] = useState(false)

  let typeArray = []

  const checkDates = (start, end) => {
    if (moment(end) < moment(start)) {
      setError('endDate', { shouldFocus: 'endDate' })
    } else {
      clearErrors('endDate')
    }
  }

  const onStartDateChange = newStartDate => {
    checkDates(newStartDate, endDate)
    setStartDate(newStartDate)
    if (moment(newStartDate) > moment(startDate)) {
      setSubmitError(false)
    }
  }

  const onEndDateChange = newEndDate => {
    checkDates(startDate, newEndDate)
    setEndDate(newEndDate)
    if (moment(newEndDate) < moment(endDate)) {
      setSubmitError(false)
    }
  }

  const groupingItemBuilder = ({ value, translation }) => {
    return (
      <MenuItem
        value={value}
        classes={{
          selected: styles.menuItemSelected
        }}
      >
        {translate(translation)}
      </MenuItem>
    )
  }

  const handleChangeOrder = event => {
    setOrderValue(event.target.value)
  }

  const handleChangeFileExtension = event => {
    if (event.target.value === 'csv' && value === 'xlsx') {
      setSubmitError(false)
    }

    setValue(event.target.value)
  }

  const handleChangeGrouping = event => {
    const newGroupingValue = event.target.value

    // Get numbers from string values
    const regex = /\d+/g
    const newGroupingNumberArray = newGroupingValue.match(regex)
    const groupingNumberArray = groupingValue.match(regex)
    const newGroupingNumber = newGroupingNumberArray ? parseInt(newGroupingNumberArray[0]) : 0
    const groupingNumber = groupingNumberArray ? parseInt(groupingNumberArray[0]) : 0

    setGroupingValue(newGroupingValue)

    if (newGroupingNumber > groupingNumber) {
      setSubmitError(false)
    }
  }

  const getDateFormat = () => {
    return activeLanguage.code === Languages[0].code ? 'dd/MM/yyyy' : 'MM/dd/yyyy'
  }

  const handleChange = event => {
    if (event.target.type === 'radio') {
      const item = event.target.value
      setValue(item)
    }
    if (event.target.type === 'checkbox') {
      const { value, checked } = event.target
      setTypeChecked([...typeChecked, value])
      if (!checked) {
        setTypeChecked(typeChecked.filter(item => item !== value))
      }
      let numberOfParams = 0
      type.map((t, i) => {
        if (getValues(`types[${i}]`) && getValues(`types[${i}]`).length > 0) {
          // Hay seleccionados
          numberOfParams++
        }
        return null
      })
      if (numberOfParams === 0) {
        setError('types', { shouldFocus: 'types' })
      } else {
        clearErrors('types')
      }
    }
  }

  const onClickCancel = () => {
    props.hideModal()
  }

  const handleSelectAll = event => {
    setIsCheckAll(!isCheckAll)
    setTypeChecked(type)
    setCheckAllIsClicked(true)
    if (isCheckAll) {
      setTypeChecked([])
    } else {
      clearErrors('types')
    }
  }

  const checkInputs = () => {
    if (moment(endDate) < moment(startDate)) return 'Invalid date'
    typeArray = []
    const data = {}
    type.map((t, i) => {
      if (getValues(`types[${i}]`) && getValues(`types[${i}]`).length > 0 && t !== 'weather')
        typeArray.push(t)
      if (t === 'weather') data.weather = getValues(`types[${i}]`).length > 0
      return null
    })
    data.params = typeArray
    data.endDate = endDate
    data.startDate = startDate
    data.fileType = value
    data.order = orderValue
    data.interval = groupingValue
    setDataState(data)
  }

  let locale
  if (activeLanguage.code === 'es') locale = localeSpanish
  if (activeLanguage.code === 'en') locale = localeEnglish

  useEffect(() => {
    const types = []
    sortedData.map(d => {
      types.push(d.type)
      return null
    })
    if (system.weather && system.weather.baseStationLocation) types.push('weather')
    setType(types)
  }, [sortedData, system])

  const isIndeterminate = () => {
    return checkAllIsClicked && typeChecked.length > 0 && typeChecked.length !== type.length
  }

  const onClickNext = () => {
    if (activeTab === 0) {
      let numberOfParams = 0
      type.map((t, i) => {
        if (getValues(`types[${i}]`) && getValues(`types[${i}]`).length > 0) {
          // Hay seleccionados
          numberOfParams++
        }
        return null
      })
      if (numberOfParams !== 0) {
        setActiveTab(1)
      } else {
        setError('types', { shouldFocus: 'types' })
      }
    }
  }

  const onClickBack = () => {
    setActiveTab(0)
  }

  const onClickDownload = () => {
    checkInputs()
  }

  const disableDates = date => {
    if ((isPremium && isOwner) || isManager) return false
    const now = new Date()
    const diffTime = Math.abs(now - date)
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

    if (diffDays > 30) return true
  }

  return (
    <StylesProvider injectFirst>
      <div className={styles.modalContainer}>
        <Typography className={styles.header}>{translate('downloadData')}</Typography>
        <div className={styles.modal}>
          <form
            noValidate
            onSubmit={handleSubmit(() => onSubmit(dataState))}
          >
            <div style={{ display: activeTab === 0 ? 'block' : 'none' }}>
              <div className={styles.firstPage}>
                <div className={styles.nav}>
                  <Typography className={styles.subheaderParamsSelected}>
                    {translate('editNavParams')}
                  </Typography>
                  <Typography className={styles.subheaderDateandFormat}>
                    {translate('navDateAndFormat')}
                  </Typography>
                </div>
                <div className={styles.subInfoWrapper}>
                  <Typography className={styles.subInfo}>
                    {translate('downloadDataForm.selectParameters')}
                  </Typography>
                </div>
                <FormControlLabel
                  className={styles.selectAllCheckboxForm}
                  control={
                    <Checkbox
                      className={cx(styles.checkbox)}
                      classes={{ checked: styles.checkedBox }}
                      name='selectAll'
                      id='selectAll'
                      indeterminate={isIndeterminate()}
                      onChange={handleSelectAll}
                      isChecked={isCheckAll}
                    />
                  }
                  label={translate('downloadDataForm.selectAll')}
                />
                <div className={styles.checkboxWrapper}>
                  {type.map((dataType, i) => {
                    return (
                      <div
                        key={i + 'dataType'}
                        className={styles.checkboxFormDiv}
                      >
                        <FormControlLabel
                          className={styles.checkboxForm}
                          control={
                            <Checkbox
                              className={cx(styles.checkbox)}
                              classes={{ checked: styles.checkedBox }}
                              onChange={handleChange}
                              checked={typeChecked.includes(dataType)}
                              required
                              name={`types[${i}]`}
                              value={dataType}
                              inputRef={register()}
                              disableRipple
                            />
                          }
                          label={translate(dataType)}
                        />
                      </div>
                    )
                  })}
                </div>
                {errors.types && (
                  <div className={styles.error}>
                    <small>{translate('validation.anyFieldRequired')}</small>
                  </div>
                )}
                <div className={styles.buttonWrapper1}>
                  <Button
                    fullWidth
                    variant='contained'
                    className={styles.buttonSiguiente}
                    onClick={onClickNext}
                  >
                    {translate('next')}
                    <ArrowForward className={styles.arrow} />
                  </Button>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    variant='contained'
                    className={styles.buttonCancelar}
                    onClick={onClickCancel}
                  >
                    {translate('cancel')}
                  </Button>
                </div>
              </div>
            </div>
            <div style={{ display: activeTab === 1 ? 'block' : 'none' }}>
              <div>
                <div className={styles.nav}>
                  <Typography className={styles.subheaderParams}>
                    {translate('editNavParams')}
                  </Typography>
                  <Typography className={styles.subheaderDateandFormatSelected}>
                    {translate('navDateAndFormat')}
                  </Typography>
                </div>
                <div>
                  <Typography className={cx(styles.subInfo, styles.litlePadddingLeft)}>
                    {translate('downloadDataForm.selectDateInterval')}
                  </Typography>
                </div>

                {!isPremium && (
                  <Tooltip
                    title={translate('businessPlan')}
                    placement='left'
                    arrow='true'
                  >
                    <div className={styles.getMoreRange}>
                      {translate('downloadDataForm.getMoreRange')}
                    </div>
                  </Tooltip>
                )}
                <div className={styles.datePickerWrapper}>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={locale}
                  >
                    <div className={styles.picker}>
                      <EventIcon className={styles.calendarIcon} />
                      <DatePicker
                        autoOk
                        ampm={false}
                        disableFuture
                        onChange={onStartDateChange}
                        shouldDisableDate={disableDates}
                        name='startDate'
                        value={startDate}
                        label={translate('startDate')}
                        format={getDateFormat()}
                        className={styles.datePicker}
                        rules={{ required: true, validate: checkInputs }}
                      />
                    </div>
                    <div className={styles.picker}>
                      <EventIcon className={styles.calendarIcon} />
                      <DatePicker
                        autoOk
                        ampm={false}
                        disableFuture
                        shouldDisableDate={disableDates}
                        name='endDate'
                        value={endDate}
                        onChange={onEndDateChange}
                        label={translate('endDate')}
                        format={getDateFormat()}
                        className={styles.datePicker}
                        rules={{ required: true, validate: checkInputs }}
                      />
                    </div>
                    {errors.endDate && (
                      <div className={styles.error}>
                        <small>{translate('validation.invalidDateRange')}</small>
                      </div>
                    )}
                    {submitError && (
                      <div className={styles.error}>
                        <small>{translate('validation.exceededDateRange')}</small>
                      </div>
                    )}
                  </MuiPickersUtilsProvider>
                </div>
                <div className={styles.fileExtensionWrapper}>
                  <Typography className={styles.subInfo}>
                    {translate('downloadDataForm.downloadFormat')}
                  </Typography>
                </div>
                <div className={styles.fileExtensionWrapper}>
                  <Typography className={styles.selectLabel}>
                    {translate('downloadDataForm.downloadExtension')}
                  </Typography>
                  <FormControl>
                    <Select
                      id='extensionFileSelect'
                      value={value}
                      onChange={handleChangeFileExtension}
                      variant='standard'
                      disableUnderline
                      className={styles.select}
                      IconComponent={KeyboardArrowDownIcon}
                      classes={{
                        icon: styles.selectIcon,
                        select: styles.selectFocus
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left'
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left'
                        },
                        getContentAnchorEl: null
                      }}
                    >
                      <MenuItem
                        value='csv'
                        classes={{
                          selected: styles.menuItemSelected
                        }}
                      >
                        {translate('csv')}
                      </MenuItem>
                      <MenuItem
                        value='xlsx'
                        classes={{
                          selected: styles.menuItemSelected
                        }}
                      >
                        {translate('xlsx')}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className={styles.fileExtensionWrapper}>
                  <Typography className={styles.selectLabel}>
                    {translate('downloadDataForm.downloadOrder')}
                  </Typography>
                  <FormControl>
                    <Select
                      id='orderSelect'
                      value={orderValue}
                      onChange={handleChangeOrder}
                      variant='standard'
                      disableUnderline
                      className={styles.select}
                      IconComponent={KeyboardArrowDownIcon}
                      classes={{
                        icon: styles.selectIcon,
                        select: styles.selectFocus
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left'
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left'
                        },
                        getContentAnchorEl: null
                      }}
                    >
                      <MenuItem
                        value='0'
                        classes={{
                          selected: styles.menuItemSelected
                        }}
                      >
                        {translate('downloadDataForm.olderDataFirst')}
                      </MenuItem>
                      <MenuItem
                        value='1'
                        classes={{
                          selected: styles.menuItemSelected
                        }}
                      >
                        {translate('downloadDataForm.latestDataFirst')}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className={styles.dataGroupingWrapper}>
                  <Typography className={styles.selectLabel}>
                    {translate('downloadDataForm.dataGrouping')}
                  </Typography>
                  <FormControl>
                    <Select
                      id='dataGrouping'
                      value={groupingValue}
                      onChange={handleChangeGrouping}
                      variant='standard'
                      disableUnderline
                      className={styles.select}
                      IconComponent={KeyboardArrowDownIcon}
                      classes={{
                        icon: styles.selectIcon,
                        select: styles.selectFocus
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left'
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left'
                        },
                        getContentAnchorEl: null
                      }}
                    >
                      {groupingItemBuilder(dataGroupingDefault)}
                      {dataGroupingValues.map(item => {
                        if (system.timeInterval <= item.minutes) {
                          return groupingItemBuilder(item)
                        }
                      })}
                    </Select>
                  </FormControl>
                </div>
                <div className={styles.buttonWrapper}>
                  <LoadingButton
                    type='submit'
                    fullWidth
                    loading={isSubmitting}
                    loadingPosition='end'
                    variant='contained'
                    className={styles.buttonSiguiente}
                    disabled={submitError}
                    onClick={onClickDownload}
                  >
                    {translate('downloadData')}
                  </LoadingButton>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    disabled={isSubmitting}
                    variant='contained'
                    className={styles.buttonCancelar}
                    onClick={onClickBack}
                  >
                    <ArrowBack className={styles.arrow} />
                    {translate('back')}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </StylesProvider>
  )
}

DownloadSettingsForm.propTypes = {
  system: PropTypes.instanceOf(Map),
  translate: PropTypes.func.isRequired
}

export default withLocalize(DownloadSettingsForm)
