import { createSelector } from 'reselect'
import {
  getEntities,
  getUserProjects as getProjectsEntities,
  getSelectedProject as getSelectedProjectEntities
} from './entities'
import { denormalize } from 'normalizr'
import * as schemas from '../../schemas'

export const getProjectsRaw = createSelector(
  [getEntities, getProjectsEntities],
  (entities, projects) => {
    projects = projects.map(a => {
      return a
    })
    return denormalize(projects, [schemas.project], entities)
  }
)

export const getSelectedProject = createSelector(
  [getEntities, getSelectedProjectEntities],

  (entities, project) => {
    return denormalize(project, schemas.project, entities)
  }
)

// export const getSelectedProject = createSelector([getUserEntities, getUserId], (entities, id) =>
//   entities.get(String(id))
// )
