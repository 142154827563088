import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withLocalize } from 'react-localize-redux'
import cx from 'classnames'
import { useForm } from 'react-hook-form'

import { makeStyles, StylesProvider } from '@material-ui/core/styles'
import {
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  InputAdornment,
  ListItem,
  ListItemText,
  TextField
} from '@material-ui/core'

import styles from './NewGroupForm.module.scss'
import { blue } from '@material-ui/core/colors'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import _ from 'lodash'
import Typography from '@material-ui/core/Typography'
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'
import { LoadingButton } from '@mui/lab'
import { orderSystemsGroupType } from 'common/functions/orderSystems'

const useStyles = makeStyles(() => ({
  input: {
    [`& fieldset`]: {
      borderRadius: 30,
      borderColor: 'grey',
      verticalAlign: 'middle'
    },
    '& label': {
      padding: 0,
      verticalAlign: 'middle'
    },
    '& label.Mui-focused': {
      color: blue[600]
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'grey'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: 'grey'
      },
      '&.Mui-focused fieldset': {
        borderColor: blue[600]
      }
    }
  },
  paper: {
    border: 'none',
    boxShadow: 'none'
  },
  wrapper: {
    width: '100%'
  }
}))

const NewGroupForm = props => {
  const classes = useStyles()

  const [open, setOpen] = useState({})
  const { translate, onSubmit, systems, onCancel, isSubmitting, projects } = props
  const [groupName, setGroupName] = useState('')
  const [filterExpression, setFilterExpression] = useState('')
  const [systemsByGroup, setSystemsByGroup] = useState([])
  const [noGroupSystems, setNoGroupSystems] = useState([])
  const [selectedSystems, setSelectedSystems] = useState([])

  const { handleSubmit, register, errors, getValues, setError, clearErrors } = useForm()

  const filteredSystems = systems.filter(system =>
    system.name.toLowerCase().includes(filterExpression.toLowerCase())
  )

  const handleClick = key => {
    const openKey = open[key]
    setOpen({ ...open, [key]: !openKey })
  }

  const orderSystemsByGroup = () => {
    const grouped = orderSystemsGroupType(filteredSystems)
    setNoGroupSystems(grouped.undefined)
    delete grouped.undefined
    setSystemsByGroup(grouped)
  }

  const validateDevicesSelected = devicesSelected => {
    if (devicesSelected) {
      clearErrors('selectedDevices')
    } else {
      setError('selectedDevices', { shouldFocus: 'selectedDevices' })
    }
  }

  const updateSelectedSystems = systemId => {
    const index = selectedSystems.findIndex(selectedId => selectedId === systemId)
    if (index === -1) {
      setSelectedSystems(current => [...current, systemId])
      validateDevicesSelected(true)
    } else {
      const array = selectedSystems
      array.splice(index, 1)
      setSelectedSystems(array)
      if (array.length > 0) {
        validateDevicesSelected(true)
      } else {
        validateDevicesSelected(false)
      }
    }
  }

  const isChecked = systemId => {
    return selectedSystems.some(selectedId => selectedId === systemId)
  }

  const checkGroupName = groupName => {
    setGroupName(groupName)
    if (groupName === '' || groupName === undefined) {
      setError('groupName', { shouldFocus: 'groupName' })
    } else {
      clearErrors('groupName')
    }
  }

  const getGroupData = (group, groupName) => {
    if (group === undefined) {
      return null
    }
    return Object.keys(group).map(type => {
      const typeId = `${groupName}-${type}`
      return (
        <div
          key={typeId}
          className={styles.typeRowWrapper}
        >
          <ListItem
            button
            disableTouchRipple
            className={styles.typeListItem}
          >
            {!open[typeId] ? (
              <ExpandLess
                onClick={() => handleClick(typeId)}
                className={styles.expandIcon}
              />
            ) : (
              <ExpandMore
                onClick={() => handleClick(typeId)}
                className={styles.expandIcon}
              />
            )}
            <ListItemText
              primary={type}
              className={styles.micaTypeItemText}
            />
          </ListItem>
          <Collapse
            in={!open[typeId]}
            timeout='auto'
            unmountOnExit
          >
            {group[type].map((system, index) => {
              return (
                <div
                  key={('Mica ItemCheckbox', index)}
                  className={styles.micaItem}
                >
                  <FormControlLabel
                    className={styles.checkboxForm}
                    label={system.name}
                    control={
                      <Checkbox
                        className={cx(styles.checkbox)}
                        classes={{ checked: styles.checkedBox }}
                        onChange={() => updateSelectedSystems(system._id)}
                        required
                        outline='true'
                        inputRef={register({
                          validate: validateDevicesSelected
                        })}
                        disableRipple
                        name={`selectedDevices[${system._id}]`}
                        value={system._id}
                        checked={isChecked(system._id)}
                      />
                    }
                  />
                </div>
              )
            })}
          </Collapse>
        </div>
      )
    })
  }

  const submitFormData = () => {
    const values = getValues({ nest: true })
    checkGroupName(values.groupName)
    validateDevicesSelected(selectedSystems.length > 0)
    if (_.isEmpty(errors)) {
      const data = {
        group: values.groupName,
        deviceIds: selectedSystems,
        projectId: projects.toJS().ProjectId
      }
      onSubmit(data)
    }
  }

  useEffect(() => {
    orderSystemsByGroup()
  }, [filterExpression])

  return (
    <StylesProvider injectFirst>
      <div className={styles.modalContainer}>
        <Typography className={styles.header}>{translate('newGroup')}</Typography>
        <div className={styles.modal}>
          <form
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <TextField
              variant='outlined'
              required
              fullWidth
              id='groupName'
              label={translate('groupName')}
              onChange={event => checkGroupName(event.target.value)}
              inputRef={register({ required: true })}
              name='groupName'
              value={groupName}
              helperText={`${groupName.length}/36`}
              inputProps={{ maxLength: 36 }}
              className={cx(styles.input, classes.input, {
                [styles.hasError]: errors.name
              })}
            />
            <div className={styles.wrapperError}>
              {errors.groupName && (
                <div className={styles.error}>
                  <small>{translate('validation.required')}</small>
                </div>
              )}
            </div>
            <TextField
              variant='outlined'
              fullWidth
              type='search'
              onChange={e => setFilterExpression(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchOutlinedIcon />
                  </InputAdornment>
                )
              }}
              id='searchDevice'
              name='searchDevice'
              placeholder={translate('searchDevice')}
              className={cx(styles.inputSearch, classes.input, {
                [styles.hasError]: errors.alarmName
              })}
            />
            <Typography className={styles.selectDevicesHelper}>
              {translate('newGroupForm.selectDevices')}
            </Typography>
            <Paper
              className={styles.devicesListPaper}
              classes={{ root: classes.paper }}
            >
              <List
                disableTouchRipple
                className={styles.devicesList}
              >
                <div className={styles.noGroupWrapper}>
                  {getGroupData(noGroupSystems, '')}
                  {Object.keys(systemsByGroup).map(group => {
                    return (
                      <div key={group}>
                        <ListItem
                          button
                          disableTouchRipple
                          className={styles.groupListItem}
                        >
                          {!open[group] ? (
                            <ExpandLess
                              onClick={() => handleClick(group)}
                              className={styles.expandIcon}
                            />
                          ) : (
                            <ExpandMore
                              onClick={() => handleClick(group)}
                              className={styles.expandIcon}
                            />
                          )}
                          <ListItemText
                            primary={group}
                            className={styles.listItemText}
                          />
                        </ListItem>
                        <Collapse
                          in={!open[group]}
                          timeout='auto'
                          unmountOnExit
                          classes={{
                            wrapper: classes.wrapper
                          }}
                        >
                          {getGroupData(systemsByGroup[group], group)}
                        </Collapse>
                      </div>
                    )
                  })}
                </div>
              </List>
            </Paper>
            <div className={styles.wrapperError}>
              {errors.selectedDevices && (
                <div className={styles.error}>
                  <small>{translate('validation.anyDeviceRequired')}</small>
                </div>
              )}
            </div>
            <div className={styles.buttonWrapper}>
              <LoadingButton
                fullWidth
                disableRipple
                variant='contained'
                onClick={submitFormData}
                className={styles.button}
                loading={isSubmitting}
                disabled={isSubmitting}
                loadingPosition='end'
              >
                {translate('addGroup')}
              </LoadingButton>
            </div>
            <div className={styles.buttonWrapper}>
              <Button
                fullWidth
                disableRipple
                variant='contained'
                className={styles.buttonCancelar}
                onClick={onCancel}
                disabled={isSubmitting}
                isLoading={isSubmitting}
              >
                {translate('cancel')}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </StylesProvider>
  )
}

NewGroupForm.propTypes = {
  translate: PropTypes.func.isRequired
}

export default withLocalize(NewGroupForm)
