import { createAction, createRequestTypes, createRequestAction } from 'utils'

export const GET_USER_PROJECTS = '@project/GET_USER_PROJECTS'
export const CREATE_PROJECT = '@project/CREATE_PROJECT'
export const DELETE_PROJECT = '@project/DELETE_PROJECT'
export const LEAVE_PROJECT = '@project/LEAVE_PROJECT'
export const SHARE_PROJECT = '@project/SHARE_PROJECT'
export const UPDATE_PROJECT = '@project/UPDATE_PROJECT'
export const SELECT_PROJECT = '@project/SELECT_PROJECT'
export const SHARE_DEVICES = '@project/SHARE_DEVICES'
export const SHARE_DEVICES_REQUEST = createRequestTypes('@project/SHARE_DEVICES_REQUEST')
export const LEAVE_PROJECT_REQUEST = createRequestTypes('@project/LEAVE_PROJECT_REQUEST')
export const DELETE_PROJECT_REQUEST = createRequestTypes('@project/DELETE_PROJECT_REQUEST')
export const LOAD_SELECTED_PROJECT = '@project/LOAD_SELECTED_PROJECT'
export const UPDATE_PROJECT_REQUEST = createRequestTypes('@project/UPDATE_PROJECT_REQUEST')
export const SHARE_PROJECT_REQUEST = createRequestTypes('@project/SHARE_PROJECT_REQUEST')
export const GET_USER_PROJECTS_REQUEST = createRequestTypes('@project/GET_USER_PROJECTS_REQUEST')
export const CREATE_PROJECT_REQUEST = createRequestTypes('@project/CREATE_PROJECT_REQUEST')
export const SELECT_PROJECT_REQUEST = createRequestTypes('@project/SELECT_PROJECT_REQUEST')
export const LOAD_SELECTED_PROJECT_REQUEST = createRequestTypes(
  '@project/LOAD_SELECTED_PROJECT_REQUEST'
)
export const constants = {
  GET_USER_PROJECTS,
  LEAVE_PROJECT,
  CREATE_PROJECT,
  SHARE_PROJECT,
  UPDATE_PROJECT,
  SELECT_PROJECT,
  LOAD_SELECTED_PROJECT,
  SHARE_DEVICES,
  SHARE_DEVICES_REQUEST,
  LOAD_SELECTED_PROJECT_REQUEST,
  SELECT_PROJECT_REQUEST,
  UPDATE_PROJECT_REQUEST,
  SHARE_PROJECT_REQUEST,
  GET_USER_PROJECTS_REQUEST,
  CREATE_PROJECT_REQUEST,
  LEAVE_PROJECT_REQUEST,
  DELETE_PROJECT
}

export const getUserProjects = createAction(GET_USER_PROJECTS)
export const createProject = createAction(CREATE_PROJECT)
export const shareProject = createAction(SHARE_PROJECT)
export const updateProject = createAction(UPDATE_PROJECT)
export const selectProject = createAction(SELECT_PROJECT)
export const deleteProject = createAction(DELETE_PROJECT)
export const leaveProject = createAction(LEAVE_PROJECT)
export const shareDevices = createAction(SHARE_DEVICES)
export const loadSelectedProject = createAction(LOAD_SELECTED_PROJECT)
export const shareDevicesRequest = createRequestAction(SHARE_DEVICES_REQUEST)
export const leaveProjectRequest = createRequestAction(LEAVE_PROJECT_REQUEST)
export const deleteProjectRequest = createRequestAction(DELETE_PROJECT_REQUEST)
export const updateProjectRequest = createRequestAction(UPDATE_PROJECT_REQUEST)
export const shareProjectRequest = createRequestAction(SHARE_PROJECT_REQUEST)
export const createProjectRequest = createRequestAction(CREATE_PROJECT_REQUEST)
export const getUserProjectsRequest = createRequestAction(GET_USER_PROJECTS_REQUEST)
export const selectProjectRequest = createRequestAction(SELECT_PROJECT_REQUEST)
export const loadSelectedProjectRequest = createRequestAction(LOAD_SELECTED_PROJECT_REQUEST)
export default {
  selectProject,
  getUserProjects,
  createProject,
  shareProject,
  updateProject,
  loadSelectedProject,
  deleteProject,
  leaveProject,
  shareDevices,
  shareDevicesRequest,
  leaveProjectRequest,
  deleteProjectRequest,
  selectProjectRequest,
  updateProjectRequest,
  shareProjectRequest,
  createProjectRequest,
  getUserProjectsRequest,
  loadSelectedProjectRequest
}
