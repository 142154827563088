import { takeLatest, call, put, all, select } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import * as api from 'api/project'
import * as schemas from 'schemas'
import actions, { constants } from 'common/actions/projects'
import modalActions from '../actions/modal'
import snackbarActions from 'common/actions/snackbar'
import { setProjectStoreData, getProjectStoreData } from 'utils/projectStore'
import * as Routes from 'constants/Routes'
import { setTokenHeader } from 'utils/request'
import { getAuthStoreData } from 'utils/authStore'

function* onGetUserProjects({ payload: data }) {
  yield put(actions.getUserProjectsRequest.start())
  try {
    const { projects } = yield call(api.getUserProjects, data)
    const norm = yield call(normalize, projects, [schemas.project])

    yield put(actions.getUserProjectsRequest.success(norm))
  } catch (err) {
    yield put(actions.getUserProjectsRequest.failure(err))
  }
}

function* onCreateProject({ payload: data }) {
  yield put(actions.createProjectRequest.start())
  try {
    const { projects } = yield call(api.createProject, data)
    const norm = yield call(normalize, projects, [schemas.project])
    yield put(actions.createProjectRequest.success(norm))
    yield put(snackbarActions.showSnackbar('snackbar.createProjectSuccessful'))
    yield put(modalActions.hideModal())
  } catch (err) {
    yield put(actions.createProjectRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onShareProject({ payload: data }) {
  yield put(actions.shareProjectRequest.start())
  try {
    const { projects } = yield call(api.shareProject, data.user_id, data)
    const norm = yield call(normalize, projects, [schemas.project])
    yield put(actions.shareProjectRequest.success(norm))
    yield put(modalActions.hideModal())
    location.reload()
  } catch (err) {
    yield put(actions.shareProjectRequest.failure(err))
  }
}

function* onUpdateProject({ payload: data }) {
  yield put(actions.updateProjectRequest.start())
  try {
    const { project } = yield call(api.updateProject, data.ProjectId, data.jsonData)
    const norm = yield call(normalize, project, schemas.project)
    yield put(actions.updateProjectRequest.success(norm))
    yield put(snackbarActions.showSnackbar('snackbar.updateProjectSuccessful'))
    yield put(modalActions.hideModal())
  } catch (err) {
    yield put(actions.updateProjectRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onSelectProject({ payload }) {
  try {
    yield call(setProjectStoreData, payload.ProjectId)
    yield put(actions.selectProjectRequest.success(payload))
  } catch (err) {
    yield put(actions.selectProjectRequest.failure(err))
  }
}

function* onLoadSelectedProject() {
  yield put(actions.loadSelectedProjectRequest.start())
  const authStoreData = getAuthStoreData()
  const routing = yield select(state => state.routing)
  // Pages to ignore
  if (
    routing.locationBeforeTransitions.pathname.includes(Routes.CHANNEL) ||
    routing.locationBeforeTransitions.pathname.includes(Routes.LOGIN) ||
    routing.locationBeforeTransitions.pathname.includes(Routes.FULLSCREEN_LINK) ||
    routing.locationBeforeTransitions.pathname.includes(Routes.FORGOTTEN_PASSWORD) ||
    routing.locationBeforeTransitions.pathname.includes(Routes.CONFIRM_USER) ||
    routing.locationBeforeTransitions.pathname.includes(Routes.RECOVER_PASSWORD) ||
    routing.locationBeforeTransitions.pathname.includes(Routes.CONFIRM_RECIPIENT) ||
    routing.locationBeforeTransitions.pathname.includes(Routes.CONFIRM_TRUSTED_USER) ||
    routing.locationBeforeTransitions.pathname.includes(Routes.SIGN_UP_TRUSTED_USER) ||
    routing.locationBeforeTransitions.pathname.includes(Routes.POLICY) ||
    routing.locationBeforeTransitions.pathname.includes(Routes.TERMS) ||
    routing.locationBeforeTransitions.pathname.includes(Routes.COOKIES) ||
    routing.locationBeforeTransitions.pathname.includes(Routes.MANAGE_COOKIES) ||
    routing.locationBeforeTransitions.pathname.includes(Routes.PROJECTS)
  ) {
    return yield put(actions.loadSelectedProjectRequest.failure(null))
  }
  const projectId = getProjectStoreData()
  setTokenHeader(authStoreData)
  if (!projectId) {
    yield put(actions.loadSelectedProjectRequest.failure())
  }

  try {
    const { projects } = yield call(api.getProjectById, projectId)
    const norm = yield call(normalize, projects, [schemas.project])
    yield put(actions.loadSelectedProjectRequest.success(norm))
    const selectedProject = norm.entities.projects[projectId]
    if (selectedProject) {
      yield put(actions.selectProjectRequest.success(selectedProject))
    }
  } catch (err) {
    yield put(actions.loadSelectedProjectRequest.failure(err))
  }
}
function* onDeleteProject({ payload: data }) {
  yield put(actions.deleteProjectRequest.start())
  try {
    yield call(api.deleteProject, data)
    yield put(actions.deleteProjectRequest.success({ data }))
    yield put(snackbarActions.showSnackbar('snackbar.deleteProjectSuccessful'))
  } catch (err) {
    yield put(actions.deleteProjectRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onLeaveProject({ payload: data }) {
  yield put(actions.leaveProjectRequest.start())
  try {
    yield call(api.leaveProject, data.id, data.userId)
    yield put(actions.leaveProjectRequest.success({ data }))
    yield put(snackbarActions.showSnackbar('snackbar.deleteProjectSuccessful'))
  } catch (err) {
    yield put(actions.leaveProjectRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onShareDevices({ payload: data }) {
  yield put(actions.shareDevicesRequest.start())
  try {
    const { project } = yield call(api.shareDevices, data)
    const norm = yield call(normalize, project, schemas.project)
    yield put(actions.shareDevicesRequest.success(norm))
    yield put(snackbarActions.showSnackbar('snackbar.shareDevicesSuccessful'))
    yield put(modalActions.hideModal())
  } catch (err) {
    yield put(actions.shareDevicesRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}
export default function* watchProjects() {
  yield all([
    takeLatest(constants.CREATE_PROJECT, onCreateProject),
    takeLatest(constants.GET_USER_PROJECTS, onGetUserProjects),
    takeLatest(constants.SHARE_PROJECT, onShareProject),
    takeLatest(constants.UPDATE_PROJECT, onUpdateProject),
    takeLatest(constants.SELECT_PROJECT, onSelectProject),
    takeLatest(constants.LOAD_SELECTED_PROJECT, onLoadSelectedProject),
    takeLatest(constants.DELETE_PROJECT, onDeleteProject),
    takeLatest(constants.LEAVE_PROJECT, onLeaveProject),
    takeLatest(constants.SHARE_DEVICES, onShareDevices)
  ])
}
