import { useState, useEffect } from 'react'

import styles from './DeleteDevicesModal.module.scss'
import { StylesProvider } from '@material-ui/core/styles'
import { withLocalize } from 'react-localize-redux'
import {
  InputAdornment,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button
} from '@material-ui/core'
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'
import { useForm } from 'react-hook-form'
import cx from 'classnames'
import PropTypes from 'prop-types'
import * as Routes from 'constants/Routes'
import { browserHistory } from 'react-router'

const DeleteDevicesModal = props => {
  const { translate, projects, systems, handleDeleteSystem, user } = props

  const [attachedProjects, setAttachedProjects] = useState({})
  const [selectedDevices, setSelectedDevices] = useState([])
  const [systemsIds, setSystemsIds] = useState([])
  const [filteredDevices, setFilteredDevices] = useState([])
  const { register, errors, setError, clearErrors } = useForm()
  useEffect(() => {
    const systemsName = []
    const systemCount = {}
    systems.forEach((system, index) => {
      systemCount[system._id] = []
      systemsName[index] = {
        name: system.name,
        id: system._id
      }
    })

    projects.forEach(project => {
      const projectDevices = project.Meta.DeviceIds
      Object.keys(projectDevices).forEach(device => {
        if (systemCount.hasOwnProperty(device)) {
          systemCount[device] = [...systemCount[device], project.ProjectId]
        }
      })
    })
    setSystemsIds(systemsName)
    setFilteredDevices(systemsName)
    setAttachedProjects(systemCount)
  }, [systems, projects])
  const handleSelectSystem = e => {
    if (e.target.checked) {
      setSelectedDevices([...selectedDevices, e.target.value])
    } else {
      setSelectedDevices(selectedDevices.filter(email => email !== e.target.value))
    }
  }
  const handleSelectAllEmails = e => {
    if (e.target.checked) {
      const devicesName = systems.map(system => system._id)
      setSelectedDevices(devicesName)
    } else {
      setSelectedDevices([])
    }
  }
  const onClickCancel = () => {
    props.hideModal()
  }

  const handleFilterExpressionChange = event => {
    const value = event.target.value
    if (value === '') {
      setFilteredDevices(systemsIds)
    } else {
      const filtered = systemsIds.filter(system => {
        return system.name.toLowerCase().includes(value.toLowerCase())
      })
      setFilteredDevices(filtered)
    }
  }

  const formatDeviceNames = selectedDevicesNames => {
    if (selectedDevicesNames.length === 0) return ''
    if (selectedDevicesNames.length === 1) return selectedDevicesNames[0]
    if (selectedDevicesNames.length === 2)
      return `${selectedDevicesNames[0]} and ${selectedDevicesNames[1]}`

    const allButLast = selectedDevicesNames.slice(0, -1).join(', ')
    const last = selectedDevicesNames[selectedDevicesNames.length - 1]
    return `${allButLast}, and ${last}`
  }
  const handleDelete = devices => {
    if (devices.length > 0) {
      clearErrors('devices')
      const selectedDevicesProjectIds = devices.reduce((acc, device) => {
        acc[device] = attachedProjects[device]
        return acc
      }, {})

      const selectedDevicesNames = selectedDevices.reduce((acc, systemId) => {
        const system = systems.find(system => system._id === systemId)
        acc.push(system.name)
        return acc
      }, [])
      const deviceName = formatDeviceNames(selectedDevicesNames)
      handleDeleteSystem(
        { deviceProjectMap: selectedDevicesProjectIds, userId: user.toJS()._id },
        () => {
          redirect(Routes.MY_DEVICES)
        },
        deviceName
      )
    } else {
      setError('devices', 'required')
    }
  }

  const redirect = route => {
    browserHistory.push(route)
  }
  return (
    <StylesProvider injectFirst>
      <div className={styles.modalContainer}>
        <Typography className={styles.header}>{translate('manageUsers')}</Typography>
        <div className={styles.modal}>
          <form noValidate>
            <Typography className={styles.subTitle}>{translate('selectUsers')}</Typography>
            <Typography className={styles.text}>{translate('checkUser')}</Typography>
            <div className={styles.searchAddWrapper}>
              <TextField
                className={styles.searchBar}
                placeholder={translate('alertsPage.searchAlerts')}
                onChange={handleFilterExpressionChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchOutlinedIcon />
                    </InputAdornment>
                  )
                }}
                variant='outlined'
              />
            </div>
            <div className={styles.checkboxEmailWrapper}>
              <FormControlLabel
                className={styles.selectAllCheckbox}
                control={
                  <Checkbox
                    className={cx(styles.checkbox)}
                    classes={{ checked: styles.checkedBox }}
                    name='selectAllEmails'
                    id='selectAllEmails'
                    onChange={handleSelectAllEmails}
                  />
                }
                label={translate('selectAll')}
              />
              {filteredDevices.map((system, i) => {
                return (
                  <div
                    key={i + 'emailData'}
                    className={styles.checkboxFormDiv}
                  >
                    <FormControlLabel
                      className={(styles.checkboxForm, styles.confirmed)}
                      control={
                        <Checkbox
                          className={cx(styles.checkbox)}
                          id={system}
                          classes={{ checked: styles.checkedBox }}
                          required
                          onChange={handleSelectSystem}
                          checked={selectedDevices.includes(system.id)}
                          name='emailRecipients'
                          value={system.id}
                          inputRef={register()}
                          disableRipple
                        />
                      }
                      label={system.name}
                    />
                    <Typography className={styles.emailCount}>
                      {`(${attachedProjects[system.id]?.length} ${
                        attachedProjects[system.id]?.length > 1
                          ? translate('projects')
                          : translate('project')
                      })`}{' '}
                    </Typography>
                  </div>
                )
              })}
            </div>
            {errors.devices && (
              <div className={styles.error}>
                <small>{translate('validation.anyDeviceRequired')}</small>
              </div>
            )}
            <div className={styles.buttonWrapper}>
              <Button
                fullWidth
                variant='contained'
                className={styles.deleteButton}
                onClick={() => handleDelete(selectedDevices)}
              >
                {translate('deleteDevices')}
              </Button>
            </div>
            <div className={styles.buttonWrapper}>
              <Button
                fullWidth
                disableRipple
                type='submit'
                variant='contained'
                className={styles.buttonCancelar}
                onClick={onClickCancel}
              >
                {translate('addReportModal.cancel')}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </StylesProvider>
  )
}
DeleteDevicesModal.propTypes = {
  translate: PropTypes.func.isRequired
}

export default withLocalize(DeleteDevicesModal)
