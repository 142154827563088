import { useState } from 'react'
import styles from './AddProjectModal.module.scss'
import { StylesProvider, makeStyles } from '@material-ui/core/styles'
import { withLocalize } from 'react-localize-redux'
import { Typography, TextField, Button } from '@material-ui/core'
import cx from 'classnames'
import { useForm } from 'react-hook-form'
import { blue } from '@material-ui/core/colors'
import PropTypes from 'prop-types'
import { LoadingButton } from '@mui/lab'

const useStyles = makeStyles(() => ({
  input: {
    [`& fieldset`]: {
      borderRadius: 30,
      borderColor: 'grey'
    },
    '& label.Mui-focused': {
      color: blue[600]
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'grey'
    },
    '& .MuiInputBase-inputMultiline': {
      padding: '12px'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: 'grey'
      },
      '&.Mui-focused fieldset': {
        borderColor: blue[600]
      }
    }
  }
}))

const AddProjectModal = props => {
  const { translate, isSubmitting, onSubmit } = props
  const [projectName, setProjectName] = useState('')
  const [descriptionField, setDescriptionField] = useState('')
  const [dataState, setDataState] = useState({})

  const { register, errors, handleSubmit, setError } = useForm()

  const handleProjectNameChange = e => {
    setProjectName(e.target.value)
  }

  const onClickCancel = () => {
    props.hideModal()
  }
  const handleDescriptionField = e => {
    setDescriptionField(e.target.value)
  }

  const onClickCreate = () => {
    if (projectName.length > 0) {
      setDataState({
        projectName: projectName,
        projectDescription: descriptionField
      })
      onSubmit({
        projectName: projectName,
        projectDescription: descriptionField
      })
    } else {
      setError('name', { shouldFocus: 'name' })
    }
  }
  const classes = useStyles()
  return (
    <StylesProvider injectFirst>
      <div className={styles.modalContainer}>
        <Typography className={styles.header}>{translate('newProject')}</Typography>
        <div className={styles.modal}>
          <form
            noValidate
            onSubmit={handleSubmit(() => onSubmit(dataState))}
          >
            <div className={styles.firstPage}>
              <TextField
                variant='outlined'
                fullWidth
                id='name'
                defaultValue={projectName}
                label={translate('projectName')}
                onKeyPress={e => {
                  e.key === 'Enter' && e.preventDefault()
                }}
                value={projectName}
                onChange={handleProjectNameChange}
                helperText={`${projectName.length}/36`}
                inputProps={{ maxLength: 36 }}
                inputRef={register({ required: true })}
                name='name'
                autoComplete='name'
                className={cx(styles.input, classes.input, {
                  [styles.hasError]: errors.name
                })}
              />
              {errors.name && (
                <div className={styles.error}>
                  <small>{translate('validation.required')}</small>
                </div>
              )}
              <TextField
                variant='outlined'
                label={translate('projectDescription')}
                inputRef={register()}
                fullWidth
                onKeyPress={e => {
                  e.key === 'Enter' && e.preventDefault()
                }}
                multiline
                expands='true'
                inputProps={{ maxLength: 160 }}
                id='description'
                name='description'
                autoComplete='description'
                value={descriptionField}
                defaultValue={descriptionField}
                onChange={handleDescriptionField}
                helperText={`${descriptionField.length}/160`}
                className={cx(styles.inputDescription, classes.input)}
              />

              <div className={styles.buttonWrapper}>
                <LoadingButton
                  loadingPosition='end'
                  fullWidth
                  variant='contained'
                  className={styles.buttonSave}
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  onClick={onClickCreate}
                >
                  {translate('createProject')}
                </LoadingButton>
              </div>
              <div className={styles.buttonWrapper}>
                <Button
                  fullWidth
                  variant='contained'
                  className={styles.buttonCancelar}
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  onClick={onClickCancel}
                >
                  {translate('cancel')}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </StylesProvider>
  )
}
AddProjectModal.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired
}

export default withLocalize(AddProjectModal)
