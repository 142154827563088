import { useState } from 'react'
import PropTypes from 'prop-types'
import { conformToMask } from 'react-text-mask'
import { Translate, withLocalize } from 'react-localize-redux'
import cx from 'classnames'
import { useForm, Controller } from 'react-hook-form'

import { ArrowForward, ArrowBack } from '@material-ui/icons'
import { StylesProvider, makeStyles } from '@material-ui/core/styles'
import { Button, TextField, Typography } from '@material-ui/core'
import { blue } from '@material-ui/core/colors'
import { Alert } from '@material-ui/lab'
import { LoadingButton } from '@mui/lab'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import Select from 'common/components/Select'
import styles from './AddSystemModalForm.module.scss'
import Languages from 'constants/Languages'
import * as BuildingFormJson from 'constants/building'
import { deviceIdMask } from 'constants/DeviceIdMask'

const useStyles = makeStyles(() => ({
  input: {
    [`& fieldset`]: {
      borderRadius: 30,
      borderColor: 'grey'
    },
    '& label.Mui-focused': {
      color: blue[600]
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'grey'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: 'grey'
      },
      '&.Mui-focused fieldset': {
        borderColor: blue[600]
      }
    }
  }
}))

const AddSystemModalForm = props => {
  const classes = useStyles()

  const { isSubmitting, initialValues, activeLanguage, translate, submitError, onSubmit } = props

  const [macAddressField, setMacAddress] = useState()
  const [nameField, setNameField] = useState('')
  const [descriptionField, setDescriptionField] = useState('')
  // const [countryField, setCountryField] = useState()

  const { building } = initialValues
  const [activeTab, setActiveTab] = useState(0)
  const [useType, setUseType] = useState('')
  const [openInfo, setOpenInfo] = useState({})
  const [infoTab, setInfoTab] = useState('')

  const { handleSubmit, register, errors, control, setValue, getValues, setError, clearErrors } =
    useForm()
  // const inputRef = useRef()
  const [typeBuildingOptionsSelect, setTypeBuildingOptionsSelect] = useState()
  const [typeOccupancyOptions, setTypeOccupancyOptions] = useState()
  const [typeVentilationOptions, setTypeVentilationOptions] = useState()

  const lang = Languages.find(l => l.code === activeLanguage.code).code

  // const onError = (errors , e) => console.log(errors,e)

  const handleonChange = event => {
    const macAddressParse = conformToMask(event.target.value, deviceIdMask, {
      guide: false
    })
    const macAddressValue = macAddressParse.conformedValue
    setMacAddress(macAddressValue)
  }

  /*
  const handleCountrySelect = (e) => {
    if (activeTab === 0) setSelectValue(e.value)
  }
*/
  const handleNameField = event => {
    setNameField(event.target.value)
  }

  const handleDescriptionField = event => {
    setDescriptionField(event.target.value)
  }

  const typeBuildingOptions = BuildingFormJson.typeOfBuilding.map(c => ({
    label: c[lang],
    value: c.value
  }))

  const antiquityOptions = BuildingFormJson.antiquity.map(c => ({
    label: c[lang],
    value: c.value
  }))

  const airtightnessOptions = BuildingFormJson.airTightness.map(c => ({
    label: c[lang],
    value: c.value
  }))

  const occupancyResidentialOptions = BuildingFormJson.occupancyResidential.map(c => ({
    label: c[lang],
    value: c.value
  }))

  const occupancyTertiaryOptions = BuildingFormJson.occupancyTertiary.map(c => ({
    label: c[lang],
    value: c.value
  }))

  const typeBuildingTertiaryOptions = BuildingFormJson.typeBuildingTertiary.map(c => ({
    label: c[lang],
    value: c.value
  }))

  const typeBuildingResidentialOptions = BuildingFormJson.typeBuildingResidential.map(c => ({
    label: c[lang],
    value: c.value
  }))

  // const typeOfSystemOptions = BuildingFormJson.typeOfSystem.map(c => ({
  //   label: c[lang],
  //   value: c.value
  // }))

  const ventilationResidentialOptions = BuildingFormJson.ventilationResidential.map(c => ({
    label: c[lang],
    value: c.value
  }))

  const ventilationTertiaryOptions = BuildingFormJson.ventilationTertiary.map(c => ({
    label: c[lang],
    value: c.value
  }))

  let initialBuildingType,
    initialVentilation,
    initialOccupancy,
    initialAntiquity,
    initialAirThightness
  // initialTypeSystem

  if (building && building.use.useType) {
    initialBuildingType = BuildingFormJson.typeBuildingResidential.find(
      c => c.value === building.use.buildingType
    )
    if (!initialBuildingType)
      initialBuildingType = BuildingFormJson.typeBuildingTertiary.find(
        c => c.value === building.use.buildingType
      )
    initialBuildingType = {
      label: initialBuildingType[lang],
      value: initialBuildingType.value
    }

    initialOccupancy = BuildingFormJson.occupancyResidential.find(
      c => c.value === building.use.occupation
    )
    if (!initialOccupancy)
      initialOccupancy = BuildingFormJson.occupancyTertiary.find(
        c => c.value === building.use.occupation
      )
    initialOccupancy = {
      label: initialOccupancy[lang],
      value: initialOccupancy.value
    }

    initialVentilation = BuildingFormJson.ventilationResidential.find(
      c => c.value === building.use.ventilation
    )
    if (!initialVentilation)
      initialVentilation = BuildingFormJson.ventilationTertiary.find(
        c => c.value === building.use.ventilation
      )
    initialOccupancy = {
      label: initialOccupancy[lang],
      value: initialOccupancy.value
    }
  }

  if (building && building.antiquity) {
    initialAntiquity = BuildingFormJson.antiquity.find(c => c.value === building.antiquity)
    initialAntiquity = {
      label: initialAntiquity[lang],
      value: initialAntiquity.value
    }
  }

  if (building && building.airTightness) {
    initialAirThightness = BuildingFormJson.airTightness.find(
      c => c.value === building.airTightness
    )
    initialAirThightness = {
      label: initialAirThightness[lang],
      value: initialAirThightness.value
    }
  }

  const handleUseTypeChange = event => {
    setUseType(event.value)
    if (event.value === 'Residential') {
      setTypeBuildingOptionsSelect(typeBuildingResidentialOptions)
      setTypeVentilationOptions(ventilationResidentialOptions)
      setTypeOccupancyOptions(occupancyResidentialOptions)
    } else {
      setTypeBuildingOptionsSelect(typeBuildingTertiaryOptions)
      setTypeVentilationOptions(ventilationTertiaryOptions)
      setTypeOccupancyOptions(occupancyTertiaryOptions)
    }
  }
  const onClickCancel = () => {
    props.hideModal()
  }

  const onClickNext = () => {
    if (
      !(getValues('macAddress') === '') &&
      !(getValues('name') === '') &&
      !(getValues('macAddress').length !== 14)
    ) {
      setActiveTab(1)
    } else {
      // set errors

      if (getValues('name') === '') {
        setError('name', { shouldFocus: 'false' })
      }
      if (getValues('macAddress') === '') {
        setError('macAddress', { shouldFocus: 'false' })
      }
      if (getValues('macAddress').length !== 14) {
        setError('macAddress', { shouldFocus: 'false' })
      }
      // erase errors

      if (!(getValues('name') === '')) {
        clearErrors('name')
      }
      if (getValues('macAddress').length === 14) {
        clearErrors('macAddress')
      }
    }
  }

  const onClickBack = () => {
    setActiveTab(0)
    // if you are coming back the validation is already
    clearErrors('macAddress')
    clearErrors('name')
  }
  const onClickAdd = () => {
    setActiveTab(1)
  }

  const error = () => {
    if (!submitError) return null

    let message = translate('submitErrors.unknown')
    if (submitError.res && submitError.res.status === 404) {
      message = translate('submitErrors.systemNotFound')
    } else if (submitError.res && submitError.res.status === 409) {
      message = translate('submitErrors.systemAlreadyInUse')
    } else if (submitError.res && submitError.res.status === 411) {
      message = translate('submitErrors.limitSystemsUser')
    }

    return (
      <Alert
        severity='error'
        className={styles.errorMessage}
      >
        {message}
      </Alert>
    )
  }

  const handleInfo = keyInfo => {
    const openDataType = openInfo[keyInfo]
    setOpenInfo({ ...openInfo, [keyInfo]: !openDataType })
    if (infoTab === keyInfo) {
      setInfoTab('')
    } else {
      setInfoTab(keyInfo)
    }
  }

  const closeInfo = keyInfo => {
    setOpenInfo({ ...openInfo, [keyInfo]: false })
    setInfoTab('')
  }

  return (
    <StylesProvider injectFirst>
      <div className={styles.modalContainer}>
        <Typography className={styles.header}>{translate('addSystem')}</Typography>
        <div className={styles.modal}>
          <form
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <div
              className={styles.firstPage}
              style={{ display: activeTab === 0 ? 'block' : 'none' }}
            >
              <div className={styles.nav}>
                <Typography className={styles.subheaderDeviceSelected}>
                  {translate('navDevice')}
                </Typography>
                <Typography className={styles.subheaderEnviroment}>
                  {translate('navEnviroment')}
                </Typography>
              </div>
              <TextField
                inputRef={register({ required: true, minLength: 14 })}
                variant='outlined'
                required
                setFocus
                autoFocus
                shouldFocusOnError
                fullWidth
                id='macAddress'
                onKeyPress={e => {
                  e.key === 'Enter' && e.preventDefault()
                }}
                label={translate('sensorId')}
                value={macAddressField}
                onChange={handleonChange}
                placeholder='XXXX-XXXX-XXXX'
                inputProps={{ maxLength: 14 }}
                name='macAddress'
                autoComplete='macAddress'
                className={cx(styles.input, classes.input, {
                  [styles.hasError]: errors.macAddress
                })}
              />
              {errors.macAddress && (
                <div className={styles.error}>
                  <small>{translate('validation.required')}</small>
                </div>
              )}
              {errors.macAddress?.type === 'minLength' && (
                <div className={styles.error}>
                  <small>{translate('validation.invalidFormat')}</small>
                </div>
              )}
              <Typography className={styles.helper}>{translate('infoId')}</Typography>
              <TextField
                variant='outlined'
                required
                fullWidth
                id='name'
                label={translate('systemName')}
                inputProps={{ maxLength: 36 }}
                onKeyPress={e => {
                  e.key === 'Enter' && e.preventDefault()
                }}
                value={nameField}
                onChange={handleNameField}
                helperText={`${nameField.length}/36`}
                inputRef={register({ required: true })}
                name='name'
                autoComplete='name'
                className={cx(styles.input, classes.input, {
                  [styles.hasError]: errors.name
                })}
              />
              {errors.name && (
                <div className={styles.error}>
                  <small>{translate('validation.required')}</small>
                </div>
              )}
              <TextField
                variant='outlined'
                label={translate('systemDescription')}
                inputRef={register()}
                fullWidth
                onKeyPress={e => {
                  e.key === 'Enter' && e.preventDefault()
                }}
                multiline
                expands='true'
                inputProps={{ maxLength: 160 }}
                id='description'
                name='description'
                autoComplete='description'
                value={descriptionField}
                onChange={handleDescriptionField}
                helperText={`${descriptionField.length}/160`}
                className={cx(styles.input, classes.input)}
              />

              <div className={styles.buttonWrapper}>
                <Button
                  fullWidth
                  disableRipple
                  variant='contained'
                  className={styles.buttonSiguiente}
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  onClick={onClickNext}
                >
                  {translate('next')}
                  <ArrowForward className={styles.arrow} />
                </Button>
              </div>
              <div className={styles.buttonWrapper}>
                <Button
                  fullWidth
                  variant='contained'
                  disableRipple
                  className={styles.buttonCancelar}
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  onClick={onClickCancel}
                >
                  {translate('cancel')}
                </Button>
              </div>
            </div>
            <div
              className={styles.secondPage}
              style={{ display: activeTab === 1 ? 'block' : 'none' }}
            >
              <div className={styles.nav}>
                <Typography className={styles.subheaderDevice}>{translate('navDevice')}</Typography>
                <Typography className={styles.subheaderEnviromentSelected}>
                  {translate('navEnviroment')}
                </Typography>
              </div>
              <div className={styles.infoPublicWrapper}>
                <Typography className={styles.buildingTypeInfoLabel}>
                  {translate('buildingTypeInfo')}
                </Typography>
              </div>
              <div className={styles.cselect}>
                <Controller
                  className={styles.wrapperSelect}
                  render={({ onChange, name }) => (
                    <div className={styles.custom}>
                      <Select
                        label={translate('systemPage.typeOfUseBuilding')}
                        handleChange={e => {
                          handleUseTypeChange(e)
                          setValue(name, e.value)
                        }}
                        autoComplete='shipping useType'
                        error={errors.useType}
                        options={typeBuildingOptions}
                        placeholder={translate('systemPage.typeOfUseBuilding')}
                        className={cx(classes.selector, styles.selector, {
                          [styles.hasError]: errors.useType
                        })}
                      />
                    </div>
                  )}
                  control={control}
                  name='building.useType'
                />
              </div>
              <div className={styles.moreInfoWrapper}>
                <Button
                  disableRipple
                  className={styles.moreInfoButton}
                  onClick={() => handleInfo('typeOfUseBuilding')}
                  variant='outlined'
                >
                  {translate('moreInfo')}
                </Button>
                <div
                  style={{
                    display: infoTab === 'typeOfUseBuilding' ? 'block' : 'none'
                  }}
                  className={styles.caja}
                >
                  <div className={styles.cajaHeader}>
                    <IconButton
                      disableRipple
                      aria-label='close'
                      className={styles.titleIconClose}
                      onClick={() => closeInfo('typeOfUseBuilding')}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  {
                    <Translate
                      id='typeOfUseBuildingInfoN'
                      options={{ renderInnerHtml: true }}
                    />
                  }
                </div>
              </div>
              <div className={styles.cselect}>
                <Controller
                  className={styles.wrapperSelect}
                  render={({ onChange, name }) => (
                    <div className={styles.custom}>
                      <Select
                        handleChange={e => {
                          setValue(name, e.value)
                        }}
                        autoComplete='shipping buildingType'
                        options={typeBuildingOptionsSelect}
                        placeholder={translate('systemPage.typeBuilding')}
                        disabled={!useType}
                        error={errors.buildingType}
                        defaultValue={initialBuildingType}
                        className={cx(classes.selector, styles.selector, {
                          [styles.hasError]: errors.building
                        })}
                      />
                    </div>
                  )}
                  control={control}
                  name='building.buildingType'
                />
              </div>
              <div className={styles.cselect}>
                <Controller
                  className={styles.wrapperSelect}
                  render={({ onChange, name }) => (
                    <div className={styles.custom}>
                      <Select
                        handleChange={e => {
                          setValue(name, e.value)
                        }}
                        autoComplete='shipping ventilation'
                        options={typeVentilationOptions}
                        placeholder={translate('systemPage.ventilationBuilding')}
                        error={errors.ventilation}
                        disabled={!useType}
                        className={cx(classes.selector, styles.selector, {
                          [styles.hasError]: errors.ventilation
                        })}
                      />
                    </div>
                  )}
                  control={control}
                  defaultValue={initialVentilation ? initialVentilation.value : null}
                  name='building.ventilation'
                />
              </div>
              <div className={styles.moreInfoWrapper}>
                <Button
                  disableRipple
                  className={styles.moreInfoButton}
                  onClick={() => handleInfo('ventilationBuilding')}
                  variant='outlined'
                >
                  {translate('moreInfo')}
                </Button>
                <div
                  style={{
                    display: infoTab === 'ventilationBuilding' ? 'block' : 'none'
                  }}
                  className={styles.caja}
                >
                  <div className={styles.cajaHeader}>
                    <IconButton
                      disableRipple
                      aria-label='close'
                      className={styles.titleIconClose}
                      onClick={() => closeInfo('ventilationBuilding')}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  {
                    <Translate
                      id='ventilationBuildingInfoN'
                      options={{ renderInnerHtml: true }}
                    />
                  }
                </div>
              </div>
              <div className={styles.cselect}>
                <Controller
                  className={styles.wrapperSelect}
                  render={({ onChange, name }) => (
                    <div className={styles.custom}>
                      <Select
                        handleChange={e => {
                          setValue(name, e.value)
                        }}
                        autoComplete='shipping occupation'
                        options={typeOccupancyOptions}
                        disabled={!useType}
                        placeholder={translate('systemPage.occupancyBuilding')}
                        error={errors.occupation}
                        className={cx(styles.selector, {
                          [styles.hasError]: errors.occupation
                        })}
                      />
                    </div>
                  )}
                  control={control}
                  defaultValue={initialOccupancy ? initialOccupancy.value : null}
                  name='building.occupation'
                />
              </div>
              <div className={styles.moreInfoWrapper}>
                <Button
                  disableRipple
                  className={styles.moreInfoButton}
                  onClick={() => handleInfo('occupancyBuilding')}
                  variant='outlined'
                >
                  {translate('moreInfo')}
                </Button>
                <div
                  style={{
                    display: infoTab === 'occupancyBuilding' ? 'block' : 'none'
                  }}
                  className={styles.caja}
                >
                  <div className={styles.cajaHeader}>
                    <IconButton
                      disableRipple
                      aria-label='close'
                      className={styles.titleIconClose}
                      onClick={() => closeInfo('occupancyBuilding')}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  {
                    <Translate
                      id='occupancyBuildingInfoN'
                      options={{ renderInnerHtml: true }}
                    />
                  }
                </div>
              </div>
              <div className={styles.cselect}>
                <Controller
                  className={styles.wrapperSelect}
                  render={({ onChange, name }) => (
                    <div className={styles.custom}>
                      <Select
                        handleChange={e => {
                          setValue(name, e.value)
                        }}
                        autoComplete='shipping antiquity'
                        options={antiquityOptions}
                        placeholder={translate('systemPage.antiquityBuilding')}
                        error={errors.antiquity}
                        className={cx(classes.selector, styles.selector, {
                          [styles.hasError]: errors.building
                        })}
                      />
                    </div>
                  )}
                  control={control}
                  defaultValue={initialAntiquity ? initialAntiquity.value : null}
                  name='building.antiquity'
                />
              </div>
              <div className={styles.cselect}>
                <Controller
                  className={styles.wrapperSelect}
                  render={({ onChange, name }) => (
                    <div className={styles.custom}>
                      <Select
                        handleChange={e => {
                          setValue(name, e.value)
                        }}
                        autoComplete='shipping airTightness'
                        options={airtightnessOptions}
                        disabled={
                          !useType &&
                          !typeBuildingOptionsSelect &&
                          !typeVentilationOptions &&
                          !typeOccupancyOptions &&
                          !antiquityOptions
                        }
                        placeholder={translate('systemPage.airTightnessLevelBuilding')}
                        error={errors.airTightness}
                        className={cx(classes.selector, styles.selector, {
                          [styles.hasError]: errors.airTightness
                        })}
                      />
                    </div>
                  )}
                  control={control}
                  defaultValue={initialAirThightness ? initialAirThightness.value : null}
                  name='building.airTightness'
                />
              </div>
              <div className={styles.moreInfoWrapper}>
                <Button
                  disableRipple
                  className={styles.moreInfoButton}
                  onClick={() => handleInfo('airTightnessLevelBuilding')}
                  variant='outlined'
                >
                  {translate('moreInfo')}
                </Button>
                <div
                  style={{
                    display: infoTab === 'airTightnessLevelBuilding' ? 'block' : 'none'
                  }}
                  className={styles.caja}
                >
                  <div className={styles.cajaHeader}>
                    <IconButton
                      disableRipple
                      aria-label='close'
                      className={styles.titleIconClose}
                      onClick={() => closeInfo('airTightnessLevelBuilding')}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  {
                    <Translate
                      id='airTightnessLevelBuildingInfoN'
                      options={{ renderInnerHtml: true }}
                    />
                  }
                </div>
              </div>
              {error()}
              <LoadingButton
                type='submit'
                fullWidth
                variant='contained'
                className={styles.buttonAdd}
                loading={isSubmitting}
                disabled={isSubmitting}
                loadingPosition='end'
                onClick={onClickAdd}
              >
                {translate('addDevice')}
              </LoadingButton>
              <div className={styles.buttonWrapper}>
                <Button
                  fullWidth
                  disableRipple
                  variant='contained'
                  className={styles.buttonCancelar}
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  onClick={onClickBack}
                >
                  <ArrowBack className={styles.arrow} />
                  {translate('back')}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </StylesProvider>
  )
}

AddSystemModalForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  submitError: PropTypes.any,
  translate: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object
}
export default withLocalize(AddSystemModalForm)
