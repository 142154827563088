import { useState, useEffect } from 'react'
import styles from './ShareProjectModal.module.scss'
import { StylesProvider, makeStyles } from '@material-ui/core/styles'
import { withLocalize, Translate } from 'react-localize-redux'
import {
  Typography,
  TextField,
  Button,
  IconButton,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import cx from 'classnames'
import { useForm } from 'react-hook-form'
import { blue } from '@material-ui/core/colors'
import PropTypes from 'prop-types'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { getUser } from 'common/selectors/user'
import { connect } from 'react-redux'
import { addTrustedUser } from 'common/actions/user'
import { LoadingButton } from '@mui/lab'

const useStyles = makeStyles(() => ({
  input: {
    [`& fieldset`]: {
      borderRadius: 30,
      borderColor: 'grey'
    },
    '& label.Mui-focused': {
      color: blue[600]
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'grey'
    },
    '& .MuiInputBase-inputMultiline': {
      padding: '12px'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: 'grey'
      },
      '&.Mui-focused fieldset': {
        borderColor: blue[600]
      }
    }
  }
}))

const ShareProjectModal = props => {
  const { translate, isSubmitting, user, addTrustedUser, isAddingEmail, project, onSubmit } = props
  const [openInfoE, setOpenInfoE] = useState(false)
  const [trustedUser, setTrustedUser] = useState([])
  const [emails, setEmails] = useState({})
  const [emailsRole, setEmailsRole] = useState({})
  const [dataState, setDataState] = useState({})
  const [confirmedUsers, setConfirmedUsers] = useState([])
  const { register, errors, setError, clearErrors, getValues, handleSubmit } = useForm()

  const handleShareProject = () => {
    const emailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
    if (getValues('addressEmail').match(emailFormat)) {
      addTrustedUser({
        email: getValues('addressEmail'),
        userId: user.toJS()._id
      })
    } else {
      setError('addressEmail', { shouldFocus: 'addressEmail' })
    }
  }
  const handleEmailChange = event => {
    if (event.target.value !== '') {
      clearErrors('addressEmail')
    } else {
      setError('addressEmail', { shouldFocus: 'addressEmail' })
    }
  }
  const handleInfoE = () => {
    setOpenInfoE(!openInfoE)
  }

  const onClickCancel = () => {
    props.hideModal()
  }
  useEffect(() => {
    if (project && project.Meta.ProjectCustomers) {
      const initialEmails = {}
      const initialEmailsRole = {}
      Object.values(project.Meta.ProjectCustomers).forEach(customer => {
        initialEmails[customer.email] = true
        initialEmailsRole[customer.email] = customer.role || 'assignRole'
        if (customer.confirmed) {
          setConfirmedUsers(prev => [...prev, customer.email])
        }
      })
      setEmails(initialEmails)
      setEmailsRole(initialEmailsRole)
    }
  }, [project])
  useEffect(() => {
    const emailsConfAux = []
    const emailsUnconfAux = []
    for (let i = 0; i < user.toJS().trustedUser?.length; i++) {
      emailsConfAux[i] = {
        email: user.toJS().trustedUser[i],
        confirmed: true
      }
    }
    for (let i = 0; i < user.toJS().unTrustedUser?.length; i++) {
      emailsUnconfAux[i] = {
        email: user.toJS().unTrustedUser[i],
        confirmed: false
      }
    }

    setTrustedUser([...emailsConfAux, ...emailsUnconfAux])
  }, [user])

  const handleChangeSelectedEmails = event => {
    if (event.target.checked) {
      clearErrors('emailRecipients')
    }
    setEmails({
      ...emails,
      [event.target.value]: event.target.checked
    })
  }
  const handlechangeRole = (event, email) => {
    setEmailsRole({
      ...emailsRole,
      [email]: event.target.value
    })
  }
  const handleShareUserProject = (emails, roles) => {
    clearErrors('addressEmail')
    const data = {}
    if (Object.keys(emails).length > 1) {
      const checkedEmails = Object.keys(emails).filter(email => emails[email])
      if (
        !checkedEmails.every(
          email =>
            roles[email] === 'viewer' || roles[email] === 'manager' || roles[email] === 'owner'
        )
      ) {
        setError('assignRole', { shouldFocus: 'assignRole' })
      } else {
        clearErrors('assignRole')
        clearErrors('emailRecipients')
        clearErrors('addressEmail')

        data.attachedUsers = checkedEmails.reduce((acc, email) => {
          acc[email] = roles[email]
          return acc
        }, {})
        setDataState(data)
        onSubmit(data)
      }
    } else {
      setError('emailRecipients', { shouldFocus: 'emailRecipients' })
    }
  }

  const classes = useStyles()

  return (
    <StylesProvider injectFirst>
      <div className={styles.modalContainer}>
        <Typography className={styles.header}>{translate('shareProjectTitle')}</Typography>
        <div className={styles.modal}>
          <form
            noValidate
            onSubmit={handleSubmit(() => onSubmit(dataState))}
          >
            <div className={styles.firstPage}>
              <Typography className={styles.shareProject}>{translate('shareProject')}</Typography>
              <Typography className={styles.subTitle}> {translate('existingRoleUsers')}</Typography>

              <div className={styles.checkboxEmailWrapper}>
                {trustedUser.map((email, i) => {
                  return (
                    <div
                      key={i + 'emailData'}
                      className={styles.checkboxFormDiv}
                    >
                      <FormControlLabel
                        className={
                          (styles.checkboxForm,
                          `${
                            confirmedUsers.includes(email.email)
                              ? `${styles.confirmed}`
                              : `${styles.notConfirmed}`
                          } `)
                        }
                        control={
                          <Checkbox
                            className={cx(styles.checkbox)}
                            id={email}
                            onChange={handleChangeSelectedEmails}
                            classes={{ checked: styles.checkedBox }}
                            required
                            checked={emails[email.email] === true}
                            name='emailRecipients'
                            value={email.email}
                            inputRef={register()}
                            disableRipple
                          />
                        }
                        label={email.email}
                      />
                      <select
                        className={styles.selectRange}
                        defaultValue={emailsRole[email.email] || 'assignRole'}
                        onChange={event => handlechangeRole(event, email.email)}
                      >
                        <option
                          value='assignRole'
                          disabled
                          className={(styles.assignRole, styles.optionStyle)}
                        >
                          {translate('assignRole')}
                        </option>

                        <option value='viewer'>{translate('viewer')}</option>
                        <option value='manager'>{translate('manager')}</option>
                      </select>
                    </div>
                  )
                })}
              </div>
              {errors.emailRecipients && (
                <div className={styles.error}>
                  <small>{translate('validation.anyEmailRequired')}</small>
                </div>
              )}
              {errors.assignRole && (
                <div className={styles.error}>
                  <small>{translate('validation.assignRole')}</small>
                </div>
              )}
              <Typography className={styles.subTitle}> {translate('addUsersToProject')}</Typography>
              <div className={styles.email}>
                <TextField
                  variant='outlined'
                  onChange={handleEmailChange}
                  label={translate('newEmail')}
                  id='addressEmail'
                  name='addressEmail'
                  error={errors.addressEmail}
                  placeholder={translate('emailAddress')}
                  inputRef={register({ required: true })}
                  className={cx(styles.emailInput, classes.input, {
                    [styles.hasError]: errors.addressEmail
                  })}
                />

                <div className={styles.wrapperInfoButton}>
                  <IconButton
                    className={styles.infoButton}
                    variant='outlined'
                    onClick={() => handleInfoE()}
                    value={' '}
                    name='infoParam'
                    disableRipple
                  >
                    <InfoOutlinedIcon />
                  </IconButton>
                </div>
              </div>
              <div className={styles.wrapperDataInfo}>
                <div
                  style={{
                    display: openInfoE ? 'block' : 'none'
                  }}
                  className={styles.cajaEmail}
                >
                  <Translate
                    id={'newUserInfoN'}
                    options={{ renderInnerHtml: true }}
                  />
                  <Button
                    fullWidth
                    disableRipple
                    variant='contained'
                    className={styles.buttonCancelar}
                    onClick={e => {
                      setOpenInfoE(false)
                    }}
                  >
                    {translate('close')}
                  </Button>
                </div>
                <div
                  id='overlayE'
                  className={styles.overlay}
                  style={{
                    display: openInfoE ? 'block' : 'none'
                  }}
                />
              </div>
              {errors.addressEmail && (
                <div className={styles.error}>
                  <small>{translate('validation.inValidEmail')}</small>
                </div>
              )}
              <div className={styles.cselect}>
                <div className={styles.buttonWrapper}>
                  <LoadingButton
                    fullWidth
                    variant='contained'
                    className={styles.addUser}
                    loading={isAddingEmail}
                    disabled={isAddingEmail}
                    onClick={handleShareProject}
                  >
                    {translate('addUserToProject')}
                  </LoadingButton>
                </div>
                <div className={styles.buttonWrapper}>
                  <LoadingButton
                    fullWidth
                    variant='contained'
                    className={styles.buttonLoadingSave}
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    loadingPosition='end'
                    onClick={() => handleShareUserProject(emails, emailsRole)}
                  >
                    {translate('save')}
                  </LoadingButton>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    variant='contained'
                    className={styles.buttonCancelar}
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    onClick={onClickCancel}
                  >
                    {translate('cancel')}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </StylesProvider>
  )
}
ShareProjectModal.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  user: getUser(state)
})
const mapActionsToProps = {
  addTrustedUser
}
export default withLocalize(connect(mapStateToProps, mapActionsToProps)(ShareProjectModal))
