import { fromJS } from 'immutable'
import { createReducer } from 'utils'

import { AUTH_USER_REQUEST, LOGIN_REQUEST, LOGOUT } from '../actions/auth'

import {
  UPDATE_USER_REQUEST,
  UPDATE_USER_PASSWORD_REQUEST,
  ADD_SYSTEM_TO_USER_REQUEST,
  UPLOAD_USER_LOGO_REQUEST,
  DELETE_USER_LOGO_REQUEST,
  CONFIRM_USER_NEWS_REQUEST,
  DELETE_ALERT_RECIPIENT_REQUEST,
  ADD_ALERT_RECIPIENT_REQUEST,
  DELETE_USER_DEVICES_REQUEST,
  ADD_COLLABORATIVE_ACCOUNT_REQUEST,
  EDIT_COLLABORATIVE_ACCOUNT_REQUEST,
  DELETE_TRUSTED_USER_REQUEST,
  DELETE_COLLABORATIVE_ACCESS_REQUEST,
  DELETE_SYSTEM_FROM_USER_REQUEST,
  DELETE_MULTIPLE_SYSTEMS_REQUEST,
  ADD_TRUSTED_USER_REQUEST
} from '../actions/user'
import {
  GET_USER_PROJECTS_REQUEST,
  CREATE_PROJECT_REQUEST,
  SHARE_PROJECT_REQUEST,
  UPDATE_PROJECT_REQUEST,
  SELECT_PROJECT_REQUEST,
  DELETE_PROJECT_REQUEST,
  SHARE_DEVICES_REQUEST,
  LEAVE_PROJECT_REQUEST
} from '../actions/projects'
import {
  GET_SYSTEM_BY_ID_REQUEST,
  GET_SYSTEMS_HOME_REQUEST,
  UPDATE_SYSTEM_REQUEST,
  UPDATE_SYSTEM_DATA_REQUEST,
  POLL_SYSTEM_REQUEST,
  POLL_SYSTEMS_REQUEST,
  DELETE_DATA_FROM_USER_SYSTEM_REQUEST,
  CREATE_API_KEY_REQUEST,
  REGENERATE_API_KEY_REQUEST,
  DELETE_API_KEY_REQUEST,
  SET_API_KEY_STATUS_REQUEST,
  CREATE_GROUP_REQUEST,
  UPDATE_GROUP_REQUEST,
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_SYSTEM_REQUEST,
  GET_SCREENS_REQUEST,
  CREATE_SCREEN_REQUEST,
  UPDATE_SCREEN_REQUEST,
  DELETE_SCREEN_REQUEST,
  GET_ALARMS_REQUEST,
  CREATE_ALARM_REQUEST,
  CREATE_REPORT_REQUEST,
  DELETE_ALARM_REQUEST,
  UPDATE_ALARM_REQUEST,
  CHANGE_ALARM_STATUS_REQUEST,
  CREATE_INACTIVITY_ALARM_REQUEST,
  UPDATE_INACTIVITY_ALARM_REQUEST,
  DELETE_INACTIVITY_ALARM_REQUEST,
  GET_INACTIVITY_ALARMS_REQUEST,
  CHANGE_INACTIVITY_ALARM_STATUS_REQUEST,
  UPDATE_SYSTEM_LIGHTS_REQUEST,
  UPDATE_SYSTEM_CALIBRATION_REQUEST,
  UPDATE_SYSTEM_PERIODICITY_REQUEST,
  UPDATE_SYSTEM_GLOBAL_LIGHTS_REQUEST,
  UPDATE_SYSTEM_GLOBAL_CALIBRATION_REQUEST,
  UPDATE_SYSTEM_GLOBAL_PERIODICITY_REQUEST,
  GET_PROJECTS_SYSTEMS_REQUEST
} from 'common/actions/systems'

import {
  GET_FULLSCREEN_BY_ID_REQUEST,
  GET_FULLSCREEN_USER_REQUEST,
  POLL_FULLSCREEN_REQUEST,
  POLL_CHANNEL_REQUEST
} from '../actions/fullscreen'

export const initialState = fromJS({
  users: {},
  systems: {},
  data: {},
  weather: {},
  screens: {},
  alarms: {},
  inactivityAlarms: {},
  projects: {},
  selectedProject: {},
  userSystems: {}
})

const setSelectedProject = (state, { payload }) => {
  return state.withMutations(state => {
    state.set('selectedProject', fromJS(payload))
    state.set('systems', fromJS({}))
    state.set('data', fromJS({}))
    state.set('weather', fromJS({}))
    state.set('screens', fromJS({}))
    state.set('alarms', fromJS({}))
    state.set('inactivityAlarms', fromJS({}))
  })
}

const mergeEntities = (state, { payload }) => {
  return state.withMutations(state =>
    Object.keys(payload.entities).reduce(
      (_state, entity) => _state.mergeDeepIn([entity], payload.entities[entity]),
      state
    )
  )
}

const updateEntities = (state, { payload }) => {
  return state.withMutations(state =>
    Object.keys(payload.entities).reduce(
      (_state, entity) => _state.setIn([entity], fromJS(payload.entities[entity])),
      state
    )
  )
}

const setUser = (state, { payload }) => {
  return state.setIn(
    ['users', String(payload.result)],
    fromJS(payload.entities.users[payload.result])
  )
}

const setUpdateAlarms = (state, { payload }) => {
  return state.withMutations(state => {
    state.setIn(['alarms', String(payload.result)], fromJS(payload.entities.alarms[payload.result]))
  })
}

const setUpdateInactivityAlarms = (state, { payload }) => {
  return state.withMutations(state => {
    state.setIn(
      ['inactivityAlarms', String(payload.result)],
      fromJS(payload.entities.inactivityAlarms[payload.result])
    )
  })
}

const setScreens = (state, { payload }) => {
  return state.withMutations(state => {
    state.setIn(['screens'], fromJS(payload.entities.screens || {}))
  })
}

const setProject = (state, { payload }) => {
  if (!payload || !payload.entities || !payload.entities.projects || !payload.result) {
    return state
  }

  return state.withMutations(state => {
    state.setIn(['selectedProject'], fromJS(payload.entities.projects[payload.result]))
    state.setIn(['projects'], fromJS(payload.entities.projects))
  })
}

const setProjectSelected = (state, { payload }) => {
  if (!payload || !payload.entities || !payload.entities.projects || !payload.result) {
    return state
  }

  return state.withMutations(state => {
    state.setIn(['selectedProject'], fromJS(payload.entities.projects[payload.result]))
  })
}

const setUserSystems = (state, { payload }) => {
  if (!payload || !payload.entities || !payload.entities.userSystems || !payload.result) {
    return state
  }
  const userSystems = payload.entities.userSystems
  return state.set('userSystems', fromJS(userSystems))
}
const setSystems = (state, { payload }) => {
  const systems = payload.entities.systems
  const data = payload.entities.data
  const weather = payload.entities.weather

  return state
    .set('systems', fromJS(systems))
    .set('data', fromJS(data))
    .set('weather', fromJS(weather))
}

const setAlarms = (state, { payload }) => {
  return state.withMutations(state => {
    state.setIn(['alarms'], fromJS(payload.entities.alarms || {}))
  })
}

const setInactivityAlarms = (state, { payload }) => {
  return state.withMutations(state => {
    state.setIn(['inactivityAlarms'], fromJS(payload.entities.inactivityAlarms || {}))
  })
}
const setReports = (state, { payload }) => {
  return state.withMutations(state =>
    Object.keys(payload.entities).reduce(
      (_state, entity) => _state.mergeDeepIn([entity], payload.entities[entity]),
      state
    )
  )
}

export default createReducer(initialState, {
  // User actions
  [LOGOUT]: () => initialState,
  [SHARE_DEVICES_REQUEST.SUCCESS]: setProjectSelected,
  [GET_USER_PROJECTS_REQUEST.SUCCESS]: setProject,
  [CREATE_PROJECT_REQUEST.SUCCESS]: mergeEntities,
  [UPDATE_PROJECT_REQUEST.SUCCESS]: mergeEntities,
  [SHARE_PROJECT_REQUEST.SUCCESS]: mergeEntities,
  [AUTH_USER_REQUEST.SUCCESS]: mergeEntities,
  [LOGIN_REQUEST.SUCCESS]: mergeEntities,
  [UPDATE_USER_REQUEST.SUCCESS]: setUser,
  [UPLOAD_USER_LOGO_REQUEST.SUCCESS]: setUser,
  [CONFIRM_USER_NEWS_REQUEST.SUCCESS]: setUser,
  [DELETE_USER_LOGO_REQUEST.SUCCESS]: (state, { payload: { userId } }) => {
    return state.updateIn(['users'], users =>
      users.map(u => {
        if (u.get('_id') !== userId) return u
        return u.set('logo', undefined)
      })
    )
  },
  [ADD_TRUSTED_USER_REQUEST.SUCCESS]: setUser,
  [UPDATE_USER_PASSWORD_REQUEST.SUCCESS]: setUser,
  [DELETE_ALERT_RECIPIENT_REQUEST.SUCCESS]: setUser,
  [ADD_ALERT_RECIPIENT_REQUEST.SUCCESS]: setUser,
  [ADD_SYSTEM_TO_USER_REQUEST.SUCCESS]: mergeEntities,
  [ADD_COLLABORATIVE_ACCOUNT_REQUEST.SUCCESS]: setUser,
  [EDIT_COLLABORATIVE_ACCOUNT_REQUEST.SUCCESS]: setUser,
  [DELETE_TRUSTED_USER_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    const sharedProjects = data.sharedProjects
    const emailsToDelete = Object.keys(sharedProjects)

    return state.updateIn(['users'], users =>
      users.map(user => {
        const updatedTrustedUser = user
          .get('trustedUser')
          .filter(email => !emailsToDelete.includes(email))
        const updatedUntrustedUser = user
          .get('unTrustedUser')
          .filter(email => !emailsToDelete.includes(email))

        return user
          .set('trustedUser', updatedTrustedUser)
          .set('unTrustedUser', updatedUntrustedUser)
      })
    )
  },

  [DELETE_COLLABORATIVE_ACCESS_REQUEST.SUCCESS]: setUser,
  [DELETE_USER_DEVICES_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    const deviceIds = data.deviceIds
    // Manually remove:
    // - system from systems

    return state.updateIn(['systems'], systems =>
      systems.filter(s => !deviceIds.includes(s.get('_id')))
    )
  },

  [DELETE_SYSTEM_FROM_USER_REQUEST.SUCCESS]: (state, { payload: data }) => {
    // Manually remove:
    // - system from systems
    // - all data from system
    return state.updateIn(['userSystems'], systems =>
      systems.filter(s => s.get('_id') !== data.deviceId)
    )
  },

  [DELETE_MULTIPLE_SYSTEMS_REQUEST.SUCCESS]: (state, { payload: data }) => {
    const deviceIds = Object.keys(data.deviceProjectMap)
    return state.withMutations(map => {
      deviceIds.forEach(deviceId => {
        const system = map.getIn(['userSystems', String(deviceId)])
        if (system) {
          map.updateIn(['userSystems'], systems => systems.filter(s => s.get('_id') !== deviceId))
        }
      })
    })
  },

  // System actions
  [GET_SYSTEM_BY_ID_REQUEST.SUCCESS]: mergeEntities,
  [GET_SYSTEMS_HOME_REQUEST.SUCCESS]: setUserSystems,
  [GET_PROJECTS_SYSTEMS_REQUEST.SUCCESS]: setSystems,
  [UPDATE_SYSTEM_REQUEST.SUCCESS]: mergeEntities,
  [UPDATE_SYSTEM_DATA_REQUEST.SUCCESS]: (state, { payload: systemId }) => {
    const system = state.getIn(['systems', String(systemId)])
    const dataIds = system.get('data')

    // (Re)set: data measurements so that can get filled again on POLL_SYSTEM_REQUEST.SUCCESS
    return state.updateIn(['data'], data =>
      data.map(d => {
        if (!dataIds.includes(d.get('_id'))) return d

        return d.set('measurements', [])
      })
    )
  },
  [UPDATE_SYSTEM_LIGHTS_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    const deviceId = data._id
    const ledOn = data.ledOn
    return state.updateIn(['systems'], systems =>
      systems.map(s => {
        if (s.get('_id') !== deviceId) return s
        return s.set('ledOn', ledOn)
      })
    )
  },
  [UPDATE_SYSTEM_CALIBRATION_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    const deviceId = data._id
    const ventilationType = data.ventilationType
    return state.updateIn(['systems'], systems =>
      systems.map(s => {
        if (s.get('_id') !== deviceId) return s
        return s.set('ventilationType', ventilationType)
      })
    )
  },
  [UPDATE_SYSTEM_PERIODICITY_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    const deviceId = data._id
    const timeInterval = data.timeInterval
    return state.updateIn(['systems'], systems =>
      systems.map(s => {
        if (s.get('_id') !== deviceId) return s
        return s.set('timeInterval', timeInterval)
      })
    )
  },
  [UPDATE_SYSTEM_GLOBAL_LIGHTS_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    const ledOn = data.ledOn
    return state.updateIn(['systems'], systems =>
      systems.map(s => {
        return s.set('ledOn', ledOn)
      })
    )
  },
  [UPDATE_SYSTEM_GLOBAL_CALIBRATION_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    const ventilationType = data.ventilationType
    return state.updateIn(['systems'], systems =>
      systems.map(s => {
        return s.set('ventilationType', ventilationType)
      })
    )
  },
  [UPDATE_SYSTEM_GLOBAL_PERIODICITY_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    const timeInterval = data.timeInterval
    return state.updateIn(['systems'], systems =>
      systems.map(s => {
        return s.set('timeInterval', timeInterval)
      })
    )
  },

  [DELETE_DATA_FROM_USER_SYSTEM_REQUEST.SUCCESS]: (state, { payload: { systemId } }) => {
    const system = state.getIn(['systems', String(systemId)])
    const dataIds = system.get('data')

    return state.updateIn(['data'], data =>
      data.map(d => {
        if (!dataIds.includes(d.get('_id'))) return d

        return d.set('measurements', [])
      })
    )
  },

  [CREATE_API_KEY_REQUEST.SUCCESS]: (state, { payload: devices }) => {
    return state.updateIn(['systems'], systems =>
      systems.map(s => {
        const updatedDevice = devices.find(device => device.deviceId === s.get('_id'))
        if (!updatedDevice) return s
        return s.set('apiKey', updatedDevice.apiKey)
      })
    )
  },
  [REGENERATE_API_KEY_REQUEST.SUCCESS]: (state, { payload: { deviceId, apiKey } }) => {
    return state.updateIn(['systems'], systems =>
      systems.map(s => {
        if (s.get('_id') !== deviceId) return s
        return s.set('apiKey', apiKey)
      })
    )
  },
  [DELETE_API_KEY_REQUEST.SUCCESS]: (state, { payload: { deviceId } }) => {
    return state.updateIn(['systems'], systems =>
      systems.map(s => {
        if (s.get('_id') !== deviceId) return s
        return s.set('apiKey', undefined)
      })
    )
  },
  [SET_API_KEY_STATUS_REQUEST.SUCCESS]: (state, { payload: { deviceId, apiKeyDisabled } }) => {
    return state.updateIn(['systems'], systems =>
      systems.map(s => {
        if (s.get('_id') !== deviceId) return s
        return s.set('apiKeyDisabled', apiKeyDisabled)
      })
    )
  },

  [CREATE_GROUP_REQUEST.SUCCESS]: setProjectSelected,
  [UPDATE_GROUP_REQUEST.SUCCESS]: mergeEntities,
  [DELETE_GROUP_REQUEST.SUCCESS]: setProjectSelected,
  [DELETE_GROUP_SYSTEM_REQUEST.SUCCESS]: setProjectSelected,

  [POLL_SYSTEMS_REQUEST.SUCCESS]: setSystems,
  [POLL_SYSTEM_REQUEST.SUCCESS]: mergeEntities,

  // FullScreenLink actions
  [GET_FULLSCREEN_BY_ID_REQUEST.SUCCESS]: mergeEntities,
  [GET_FULLSCREEN_USER_REQUEST.SUCCESS]: setUser,
  [POLL_FULLSCREEN_REQUEST.SUCCESS]: updateEntities,
  [POLL_CHANNEL_REQUEST.SUCCESS]: mergeEntities,

  [GET_SCREENS_REQUEST.SUCCESS]: setScreens,
  [CREATE_SCREEN_REQUEST.SUCCESS]: mergeEntities,
  [UPDATE_SCREEN_REQUEST.SUCCESS]: setScreens,
  [DELETE_SCREEN_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    return state.updateIn(['screens'], screens => screens.filter(s => s.get('ScreenId') !== data))
  },

  [CREATE_REPORT_REQUEST.SUCCESS]: setReports,

  [GET_ALARMS_REQUEST.SUCCESS]: setAlarms,
  [CREATE_ALARM_REQUEST.SUCCESS]: mergeEntities,
  [UPDATE_ALARM_REQUEST.SUCCESS]: setUpdateAlarms,
  [DELETE_ALARM_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    return state.updateIn(['alarms'], alarms => alarms.filter(a => a.get('AlarmId') !== data))
  },

  [CHANGE_ALARM_STATUS_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    return state.updateIn(['alarms'], alarms =>
      alarms.map(a => {
        if (a.get('AlarmId') !== data.AlarmId) return a
        return a.set('active', data.jsonData.active)
      })
    )
  },

  [GET_INACTIVITY_ALARMS_REQUEST.SUCCESS]: setInactivityAlarms,
  [CREATE_INACTIVITY_ALARM_REQUEST.SUCCESS]: mergeEntities,
  [UPDATE_INACTIVITY_ALARM_REQUEST.SUCCESS]: setUpdateInactivityAlarms,
  [DELETE_INACTIVITY_ALARM_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    return state.updateIn(['inactivityAlarms'], inactivityAlarms =>
      inactivityAlarms.filter(a => a.get('InactivityAlarmId') !== data)
    )
  },
  [CHANGE_INACTIVITY_ALARM_STATUS_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    return state.updateIn(['inactivityAlarms'], inactivityAlarms =>
      inactivityAlarms.map(a => {
        if (a.get('InactivityAlarmId') !== data.InactivityAlarmId) return a
        return a.set('active', data.jsonData.active)
      })
    )
  },
  [DELETE_PROJECT_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    return state.updateIn(['projects'], projects =>
      projects.filter(p => p.get('ProjectId') !== data)
    )
  },
  [LEAVE_PROJECT_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    return state.updateIn(['projects'], projects =>
      projects.filter(p => p.get('ProjectId') !== data.id)
    )
  },
  [SELECT_PROJECT_REQUEST.SUCCESS]: setSelectedProject
})
