import { useState } from 'react'

import styles from './ManageDevicesModal.module.scss'
import { makeStyles, StylesProvider } from '@material-ui/core/styles'
import { withLocalize } from 'react-localize-redux'
import {
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  TextField,
  InputAdornment,
  ListItem,
  ListItemText,
  Collapse
} from '@material-ui/core'
import { LoadingButton } from '@mui/lab'

import _ from 'lodash'

import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'
import { useForm } from 'react-hook-form'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { blue } from '@material-ui/core/colors'

const useStyles = makeStyles(() => ({
  input: {
    [`& fieldset`]: {
      borderRadius: 30,
      borderColor: 'grey'
    },
    '& label.Mui-focused': {
      color: blue[600]
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'grey'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: 'grey'
      },
      '&.Mui-focused fieldset': {
        borderColor: blue[600]
      }
    }
  },
  paper: {
    border: 'none',
    boxShadow: 'none'
  },
  wrapper: {
    width: '100%'
  }
}))
const ManageDevicesModal = props => {
  const { translate, userSystems, systems, projects, isSubmitting, onSubmit } = props

  const [filterExpression, setFilterExpression] = useState('')
  const [isCheckAllDevices, setIsCheckAllDevices] = useState(false)
  const [selectedDevices, setSelectedDevices] = useState(systems.map(system => system._id))
  const [checkAllDevicesIsClicked, setCheckDevicesAllIsClicked] = useState(false)
  const [open, setOpen] = useState({})

  const classes = useStyles()
  const { register, errors, setError, clearErrors } = useForm()
  const handleFilterExpressionChange = event => {
    setFilterExpression(event.target.value)
  }
  const isIndeterminateDevices = () => {
    return (
      checkAllDevicesIsClicked &&
      selectedDevices.length > 0 &&
      selectedDevices.length !== userSystems.length
    )
  }
  const handleChangeDevice = event => {
    const { checked, value } = event.target
    if (checked) {
      setSelectedDevices([...selectedDevices, value])
    } else {
      setSelectedDevices(selectedDevices.filter(item => item !== value))
    }
  }

  const handleSelectAllGroupDevices = (event, systems) => {
    if (event.target.checked) {
      const aux = []
      for (let i = 0; i < systems.length; i++) {
        if (!selectedDevices.includes(systems[i]._id)) aux.push(systems[i]._id)
      }
      setSelectedDevices(selectedDevices => [...selectedDevices, ...aux])
      clearErrors('selectedDevices')
    } else {
      const systemIds = []
      for (let i = 0; i < systems.length; i++) {
        systemIds.push(systems[i]._id)
      }
      setSelectedDevices(selectedDevices.filter(s => !systemIds.includes(s)))
    }
  }

  const handleSelectAllDevices = event => {
    setIsCheckAllDevices(!isCheckAllDevices)
    setCheckDevicesAllIsClicked(true)
    if (isCheckAllDevices) {
      setSelectedDevices([])
    } else {
      const systemIds = []
      userSystems.map(s => {
        systemIds.push(s._id)
        return null
      })
      setSelectedDevices(systemIds)
      clearErrors('selectedDevices')
    }
  }
  const handleClick = key => {
    const openKey = open[key]
    setOpen({ ...open, [key]: !openKey })
  }

  let systemsJS = []

  if (filterExpression !== '') {
    systemsJS = userSystems.filter(s => {
      return s.name.toLowerCase().indexOf(filterExpression.toLowerCase()) !== -1
    })
  } else {
    systemsJS = userSystems
  }
  const systemsGroup = _.groupBy(systemsJS, 'group')

  const handleClickAddDevices = () => {
    if (selectedDevices.length === 0) {
      setError('selectedDevices', { shouldFocus: 'selectedDevices' })
    } else {
      const jsonData = {}
      const groups = {}
      for (let i = 0; i < selectedDevices.length; i++) {
        groups[selectedDevices[i]] = ''
      }
      const devices = projects.toJS().Meta.DeviceIds
      for (let device in devices) {
        if (selectedDevices.includes(device)) {
          groups[device] = devices[device]
        }
      }
      jsonData.devices = groups
      onSubmit(jsonData)
    }
  }

  const onClickCancel = () => {
    props.hideModal()
  }
  return (
    <StylesProvider injectFirst>
      <div className={styles.modalContainer}>
        <Typography className={styles.header}>{translate('manageDevices')}</Typography>
        <div className={styles.modal}>
          <form noValidate>
            <Typography className={styles.subTitle}>{translate('selectDevicesTitle')}</Typography>
            <Typography className={styles.text}>{translate('selectDevices')}</Typography>
            <TextField
              variant='outlined'
              fullWidth
              type='search'
              onChange={handleFilterExpressionChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchOutlinedIcon />
                  </InputAdornment>
                )
              }}
              id='searchDevice'
              name='searchDevice'
              placeholder={translate('searchDevice')}
              inputProps={{ maxLength: 32 }}
              className={cx(styles.input, classes.input, {
                [styles.hasError]: errors.alarmName
              })}
            />
            <div className={styles.subInfoWrapper}>
              <Typography className={styles.subInfo}>
                {translate('downloadDataForm.selectDevices')}
              </Typography>
            </div>
            <FormControlLabel
              className={styles.selectAllCheckboxForm}
              control={
                <Checkbox
                  className={cx(styles.checkbox)}
                  classes={{ checked: styles.checkedBox }}
                  name='selectAllDevices'
                  id='selectAllDevices'
                  indeterminate={isIndeterminateDevices()}
                  onChange={handleSelectAllDevices}
                  checked={isCheckAllDevices}
                />
              }
              label={translate('downloadDataForm.selectAll')}
            />
            <Paper
              className={styles.devicesListPaper}
              classes={{ root: classes.paper }}
            >
              <List
                disableTouchRipple
                className={styles.devicesList}
              >
                {_.map(systemsGroup, function (userSystems, group) {
                  const systemsType = _.groupBy(userSystems, 'micaType')
                  return (
                    <div className={styles.noGroupWrapper}>
                      {group !== 'undefined' && (
                        <div>
                          <ListItem
                            button
                            disableTouchRipple
                            className={styles.groupListItem}
                          >
                            {!open[group] ? (
                              <ExpandLess
                                onClick={() => handleClick(group)}
                                className={styles.expandIcon}
                              />
                            ) : (
                              <ExpandMore
                                onClick={() => handleClick(group)}
                                className={styles.expandIcon}
                              />
                            )}
                            <Checkbox
                              className={cx(styles.checkbox)}
                              classes={{ checked: styles.checkedBox }}
                              name={group}
                              onChange={e => handleSelectAllGroupDevices(e, userSystems)}
                              id={group}
                            />
                            <ListItemText
                              primary={group}
                              className={styles.listItemText}
                            />
                          </ListItem>
                        </div>
                      )}
                      {group === 'undefined' && (
                        <div>
                          {_.map(systemsType, function (userSystems, type) {
                            return (
                              <div className={styles.typeRowWrapper}>
                                <ListItem
                                  button
                                  disableTouchRipple
                                  className={styles.typeListItem}
                                >
                                  {!open[type] ? (
                                    <ExpandLess
                                      onClick={() => handleClick(type)}
                                      className={styles.expandIcon}
                                    />
                                  ) : (
                                    <ExpandMore
                                      onClick={() => handleClick(type)}
                                      className={styles.expandIcon}
                                    />
                                  )}
                                  <ListItemText
                                    primary={type}
                                    className={styles.micaTypeItemText}
                                  />
                                </ListItem>
                                <Collapse
                                  in={!open[type]}
                                  timeout='auto'
                                  unmountOnExit
                                >
                                  {userSystems.map((system, index) => {
                                    return (
                                      <div
                                        key={system._id}
                                        className={styles.micaItem}
                                      >
                                        <FormControlLabel
                                          className={styles.checkboxForm}
                                          control={
                                            <Checkbox
                                              className={cx(styles.checkbox)}
                                              classes={{
                                                checked: styles.checkedBox
                                              }}
                                              checked={selectedDevices.includes(system._id)}
                                              onChange={handleChangeDevice}
                                              required
                                              outline='true'
                                              inputRef={register()}
                                              disableRipple
                                              name={`selectedDevices[]`}
                                              value={system._id}
                                            />
                                          }
                                          label={system.name}
                                        />
                                      </div>
                                    )
                                  })}
                                </Collapse>
                              </div>
                            )
                          })}
                        </div>
                      )}

                      {group !== 'undefined' && (
                        <Collapse
                          in={!open[group]}
                          timeout='auto'
                          unmountOnExit
                          classes={{
                            wrapper: classes.wrapper
                          }}
                        >
                          {_.map(systemsType, function (userSystems, type) {
                            return (
                              <div className={styles.typeRowWrapper}>
                                <ListItem
                                  button
                                  disableTouchRipple
                                  className={styles.typeListItem}
                                >
                                  {!open[type] ? (
                                    <ExpandLess
                                      onClick={() => handleClick(type)}
                                      className={styles.expandIcon}
                                    />
                                  ) : (
                                    <ExpandMore
                                      onClick={() => handleClick(type)}
                                      className={styles.expandIcon}
                                    />
                                  )}
                                  <ListItemText
                                    primary={translate(type)}
                                    className={styles.micaTypeItemText}
                                  />
                                </ListItem>
                                <Collapse
                                  in={!open[type]}
                                  timeout='auto'
                                  unmountOnExit
                                >
                                  {userSystems.map((system, index) => {
                                    return (
                                      <div
                                        key={index + 'systemFormControlLabelCheckbox'}
                                        className={styles.micaItem}
                                      >
                                        <FormControlLabel
                                          className={styles.checkboxForm}
                                          control={
                                            <Checkbox
                                              className={cx(styles.checkbox)}
                                              classes={{
                                                checked: styles.checkedBox
                                              }}
                                              checked={selectedDevices.includes(system._id)}
                                              onChange={handleChangeDevice}
                                              required
                                              outline='true'
                                              inputRef={register()}
                                              disableRipple
                                              name={`selectedDevices[]`}
                                              value={system._id}
                                            />
                                          }
                                          label={system.name}
                                        />
                                      </div>
                                    )
                                  })}
                                </Collapse>
                              </div>
                            )
                          })}
                        </Collapse>
                      )}
                    </div>
                  )
                })}
              </List>
            </Paper>
            {errors.selectedDevices && (
              <div className={styles.error}>
                <small>{translate('validation.anyDeviceRequired')}</small>
              </div>
            )}
            <div className={styles.buttonWrapper}>
              <LoadingButton
                fullWidth
                loading={isSubmitting}
                loadingPosition='end'
                variant='contained'
                className={styles.buttonSiguiente}
                onClick={handleClickAddDevices}
              >
                {translate('saveProjectDevices')}
              </LoadingButton>
            </div>
            <div className={styles.buttonWrapper}>
              <Button
                fullWidth
                disabled={isSubmitting}
                variant='contained'
                className={styles.buttonCancelar}
                onClick={onClickCancel}
              >
                {translate('cancel')}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </StylesProvider>
  )
}
ManageDevicesModal.propTypes = {
  translate: PropTypes.func.isRequired
}

export default withLocalize(ManageDevicesModal)
