import request from 'utils/request'

export function getUserProjects(userId) {
  return request(`systems/projects/user/${userId}`, {
    method: 'GET'
  }).then(([body]) => body)
}

export function createProject(data) {
  return request(`systems/projects`, {
    method: 'POST',
    data
  }).then(([body]) => body)
}

export function updateProject(id, data) {
  return request(`systems/projects/${id}`, {
    method: 'PUT',
    data
  }).then(([body]) => body)
}

export function shareProject(id, data) {
  return request(`systems/projects/${id}/share`, {
    method: 'POST',
    data
  }).then(([body]) => body)
}

export function getProjectById(id) {
  return request(`systems/projects/${id}`, {
    method: 'GET'
  }).then(([body]) => body)
}

export function deleteProject(id) {
  return request(`systems/projects/${id}`, {
    method: 'DELETE'
  })
}

export function leaveProject(projectId, userId) {
  return request(`systems/projects/${projectId}/leave/${userId}`, {
    method: 'DELETE'
  })
}

export function shareDevices(data) {
  return request(`systems/share-devices`, {
    method: 'POST',
    data
  }).then(([body]) => body)
}
