import { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { withLocalize } from 'react-localize-redux'
import { Modal } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styles from './EditGroupContainer.module.scss'
import { showSnackbar } from 'common/actions/snackbar'
import { createGroup, deleteGroupSystem } from 'common/actions/systems'
import EditGroupForm from '../components/EditGroupForm/EditGroupForm'
import ConfirmationModal from '../../ConfirmationModal'
import { getError, getIsSubmitting } from '../selectors'
import _ from 'lodash'

const EditGroupContainer = props => {
  const [open] = useState(true)
  const [confirmingDelete, setConfirmingDelete] = useState(false)
  const [confirmingDeleteId, setConfirmingDeleteId] = useState(null)
  const [confirmingDeleteSystemName, setConfirmingDeleteSystemName] = useState('')

  const { hideModal, translate, group, createGroup, deleteGroupSystem, systems, projects } = props
  const systemsGroup = _.groupBy(systems, 'group')

  const handleSubmit = d => {
    const devicesIds = d.deviceIds || []
    // Si ha cambiado el nombre del grupo, añadimos también los pertenecientes al grupo para ser actualizados
    if (d.group !== group) {
      for (let i = 0; i < systemsGroup[group].length; i++) {
        devicesIds.push(systemsGroup[group][i]._id)
      }
    }
    const data = {
      projectId: projects.toJS().ProjectId,
      group: d.group,
      deviceIds: devicesIds
    }
    createGroup(data)
  }

  const handleDeleteGroupDevice = (id, deviceName) => {
    setConfirmingDeleteSystemName(deviceName)
    setConfirmingDeleteId(id)
    setConfirmingDelete(true)
  }

  const confirmDeleteGroupDevice = () => {
    const data = {
      deviceId: confirmingDeleteId,
      projectId: projects.toJS().ProjectId
    }
    deleteGroupSystem(data)
    setConfirmingDelete(false)
    hideModal()
  }

  const StyledPaper = withStyles({
    root: {
      maxHeight: '100% !important',
      'overflow-y': 'visible',
      fontFamily: 'Roboto',
      verticalAlign: 'top',
      maxWidth: '500px'
    }
  })

  return (
    <Modal
      open={open}
      onClose={hideModal}
      arial-labelledby='simple-modal-title'
      arial-desccribedby='simple-modal-description'
      BackdropProps={{
        classes: {
          root: styles.backdrop
        }
      }}
      PaperComponent={StyledPaper}
    >
      <div className={styles.content}>
        <EditGroupForm
          {...props}
          editingGroup={group}
          onSubmit={handleSubmit}
          onDeleteGroupDevice={handleDeleteGroupDevice}
          onCancel={hideModal}
        />
        {confirmingDelete && (
          <ConfirmationModal
            hideModal={() => setConfirmingDelete(false)}
            translate={translate}
            onConfirm={confirmDeleteGroupDevice}
            contentText={translate('deleteSystemFromGroupConfirmationText.p1')}
            groupName={group}
            deviceName={confirmingDeleteSystemName}
          />
        )}
      </div>
    </Modal>
  )
}

EditGroupContainer.propTypes = {
  submitError: PropTypes.any,
  hideModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  submitError: getError(state),
  isSubmitting: getIsSubmitting(state)
})

const mapActionsToProps = {
  createGroup,
  deleteGroupSystem,
  showSnackbar
}

export default withLocalize(connect(mapStateToProps, mapActionsToProps)(EditGroupContainer))
