import { useState } from 'react'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { Modal } from '@material-ui/core'
import styles from './DeleteDevicesContainer.module.scss'
import DeleteDevicesModal from '../components/DeleteDevicesModal'
import { getProjectsRaw } from 'common/selectors/projects'
import { getUser } from 'common/selectors/user'
import { deleteMultipleSystems } from 'common/actions/user'
import { getUserSystemsRaw } from 'common/selectors/systems'
import * as ModalTypes from 'constants/ModalTypes'
import { showModal, hideModal } from 'common/actions/modal'

const DeleteDevicesContainer = props => {
  const [open] = useState(true)
  const { hideModal, projects, user, translate, userSystems } = props

  const systemJS = []
  if (userSystems) {
    userSystems.toArray().map(a => systemJS.push(a.toJS()))
  }
  const projectJS = []
  if (projects) {
    projects.toArray().map(a => projectJS.push(a.toJS()))
  }

  const handleDeleteSystem = (data, confirmedCallback, deviceName = '') => {
    props.showModal({
      type: ModalTypes.CONFIRMATION_MODAL,
      props: {
        translate,
        onConfirm: () => {
          props.deleteMultipleSystems(data)
          hideModal()
          confirmedCallback()
        },
        contentText: translate('deleteSystemConfirmationText.p1'),
        deviceName: deviceName
      }
    })
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={hideModal}
        scroll='body'
        BackdropProps={{
          classes: {
            root: styles.backdrop
          }
        }}
        arial-labelledby='simple-modal-title'
        arial-desccribedby='simple-modal-description'
      >
        <div>
          <DeleteDevicesModal
            {...props}
            projects={projectJS}
            systems={systemJS}
            handleDeleteSystem={handleDeleteSystem}
            user={user}
          />
        </div>
      </Modal>
    </div>
  )
}

DeleteDevicesContainer.propTypes = {
  hideModal: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  projects: getProjectsRaw(state),
  user: getUser(state),
  userSystems: getUserSystemsRaw(state)
})

const mapActionsToProps = {
  deleteMultipleSystems,
  showModal,
  hideModal
}

export default withLocalize(connect(mapStateToProps, mapActionsToProps)(DeleteDevicesContainer))
