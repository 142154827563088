import { useEffect, useState } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'

import { Modal } from '@material-ui/core'

import AddInactivityAlertModalForm from '../components/AddInactivityAlertModalForm'
import { getUser } from 'common/selectors/user'
import { getError, getIsSubmitting } from '../selectors'
import { createInactivityAlarm } from 'common/actions/systems'
import { resetError } from 'common/actions/error'
import { hideModal } from 'common/actions/modal'
import { addAlertRecipient, deleteAlertRecipient } from 'common/actions/user'
import styles from './AddInactivityAlertModalContainer.module.scss'
import ConfirmationModal from 'modules/modals/ConfirmationModal'

const AddInactivityAlertModalContainer = props => {
  const [open] = useState(true)
  const [confirmingDeleteEmail, setConfirmingDeleteEmail] = useState(null)
  const [confirmingDelete, setConfirmingDelete] = useState(false)

  const {
    hideModal,
    createInactivityAlarm,
    user,
    resetError,
    systems,
    deleteAlertRecipient,
    translate,
    projectId
  } = props
  const systemsByGroup = _.groupBy(systems, 'group')

  const handleSubmit = async data => {
    const emailsSelected = data.emailRecipients
    const jsonData = {}
    const jsonDataEmail = {}
    const emails = []

    for (let i = 0; i < emailsSelected.length; i++) {
      emails[i] = emailsSelected[i].toLowerCase()
    }

    const userPeriod = {
      rangeValue: data.rangeValue,
      rangeUnit: data.rangeUnit
    }

    const names = []
    for (let i = 0; i < data.devices.length; i++) {
      const system = systems.filter(s => {
        return s._id === data.devices[i]
      })[0]
      names.push(system.name)
    }
    jsonData.projectId = projectId

    jsonDataEmail.userId = user.toJS()._id
    jsonData.name = data.alarmName
    jsonData.userPeriod = userPeriod
    jsonData.DeviceIds = data.devices
    jsonData.emailRecipients = emails
    jsonData.CustomerId = user.toJS()._id
    jsonData.active = true
    createInactivityAlarm(jsonData)
  }

  useEffect(() => {
    resetError()
  }, [resetError])

  const handleDeleteAlertRecipient = email => {
    setConfirmingDeleteEmail(email)
    setConfirmingDelete(true)
  }

  const confirmDeleteAlertRecipient = () => {
    deleteAlertRecipient({
      email: confirmingDeleteEmail,
      userId: user.toJS()._id
    })
    setConfirmingDelete(false)
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={hideModal}
        arial-labelledby='simple-modal-title'
        arial-desccribedby='simple-modal-description'
      >
        <div className={styles.content}>
          <AddInactivityAlertModalForm
            {...props}
            systems={systems}
            systemsByGroup={systemsByGroup}
            hideModal={hideModal}
            onSubmit={handleSubmit}
            onDeleteAlertRecipient={handleDeleteAlertRecipient}
          />
          {confirmingDelete && (
            <ConfirmationModal
              hideModal={() => setConfirmingDelete(false)}
              translate={translate}
              onConfirm={confirmDeleteAlertRecipient}
              contentText={translate('deleteAlertRecipientConfirmationText')}
              contentDetail={confirmingDeleteEmail}
            />
          )}
        </div>
      </Modal>
    </div>
  )
}

AddInactivityAlertModalContainer.propTypes = {
  hideModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  contentText: PropTypes.string,
  title: PropTypes.string,
  buttonName: PropTypes.string
}

const mapStateToProps = state => ({
  user: getUser(state),
  submitError: getError(state),
  isSubmitting: getIsSubmitting(state)
})

const mapActionsToProps = {
  createInactivityAlarm,
  addAlertRecipient,
  deleteAlertRecipient,
  resetError,
  hideModal
}

export default withLocalize(
  connect(mapStateToProps, mapActionsToProps)(AddInactivityAlertModalContainer)
)
